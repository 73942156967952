import React, {useState, useEffect} from 'react';
import { BrowserRouter as Router, Switch, Redirect, Route, Link, useHistory, useParams } from "react-router-dom";
import LandUseIcon from './../LandUseIcons/LandUseIcon';
import {useData} from '../../services/data_service';
import {useStateHooks} from '../../services/state_hook_service';
import './LandUseSelect.scss';

export default function LandUseSelect(props){
	const [landUseArray, setLandUseArray] = useState([]);
	const [rangelandButton, setRangelandButton] = useState({});
	const [pastureButton, setPasturelandButton] = useState({});
	const [forestlandButton, setForestlandButton] = useState({})
	const [cropButton, setCroplandButton] = useState({})
	const history = useHistory();
	const DataSvc = useData();
	const StateHookSvc = useStateHooks();

	useEffect(() => {
		DataSvc.fetchLandUseIds().then(res => {
			if(StateHookSvc.landUseArray == [] || !StateHookSvc.landUseArray || StateHookSvc.landUseArray.length == 0){
				console.log('fetch land use ids res', res);
				StateHookSvc.setLandUseArray(res)
			}
			orderLandUseButtons();
		})
		.catch((err) => console.log('get_landuse error', err));
		DataSvc.getChordChartTooltips();
		DataSvc.getFirstViolinTooltips();
	},[StateHookSvc.landUseArray])

	function orderLandUseButtons(){
		// console.log('StateHookSvc.landUseArray', StateHookSvc.landUseArray);
		if(!StateHookSvc.landUseArray.length){ return null}
		for(let i = 0; i < StateHookSvc.landUseArray.length; i++){
			if(StateHookSvc.landUseArray[i].landuse == 'Rangeland'){
				setRangelandButton(StateHookSvc.landUseArray[i]);
			}
			else if(StateHookSvc.landUseArray[i].landuse == 'Cropland'){
				setCroplandButton(StateHookSvc.landUseArray[i]);
			}
			else if(StateHookSvc.landUseArray[i].landuse == 'Pastureland'){
				setPasturelandButton(StateHookSvc.landUseArray[i]);
			}
			else if(StateHookSvc.landUseArray[i] == 'Forestland'){
				setForestlandButton(StateHookSvc.landUseArray[i]);
				// console.log('StateHookSvc.landUseArray[i]', StateHookSvc.landUseArray[i])
			}
		}
	};

	function countHandling(val){
		if(val == null){
			return 'Coming Soon';
		}
		else if (val == 1){
			return '1 Study';
		}
		else{
			return `${val} Studies`;
		}
	};

	function disableHandling(num){
		if(num > 0){
			// console.log('true')
			return false
		}else{
			// console.log('false')
			return true
		}
	}

	return(
		<div className="landuse-select-buttons-container" id="landuse-select-buttons-container">
				<button 
					className="landuse-button-container"
					key={rangelandButton.landuse_id}
					value={rangelandButton.landuse_id}
					disabled={disableHandling(rangelandButton.studycount)}
					style={rangelandButton.studycount > 0 ? {'cursor': 'pointer'} : {'cursor': 'not-allowed'}}
					title="Rangelands are described as lands on which the indigenous vegetation is predominately grasses, grass-like plants, forbs, and possibly shrubs or dispersed trees.   Existing plant communities can include both native and introduced plants. Disturbed lands that have been revegetated naturally or artificially are included. Management of rangelands occurs primarily through ecological processes, rather than agronomic applications. Grazing, by both domestic livestock and wildlife, is the most common ecological management process, with fire and weather extremes also being significant ecological factors. Rangelands include grasslands, savannas, shrublands, most deserts, tundra, alpine communities, marshes and meadows."
					onClick={
						(e) => {
							StateHookSvc.setSelectedLandUse(rangelandButton);
							history.push('/explore');
						}
					}
				>
					<LandUseIcon landUseText={'Rangeland'}/>
					<p>Rangeland</p>
					<p className="studycount">{countHandling(rangelandButton.studycount)}</p>
				</button> 
			
				<button 
					className="landuse-button-container"
					key={pastureButton.landuse_id}
					value={pastureButton.landuse_id}
					disabled={disableHandling(pastureButton.studycount)}
					style={pastureButton.studycount > 0 ? {'cursor': 'pointer'} : {'cursor': 'not-allowed'}}
					title="Pasture is a land use type having vegetation cover comprised primarily of introduced or enhanced native forage species that is used for livestock grazing. Pasture receives periodic renovation and cultural treatments such as tillage, fertilization, mowing, weed control, and may be irrigated.  Pasture vegetation can consist of grasses, legumes, other forbs, shrubs or a mixture. Pasture differs from range in that it primarily produces vegetation that has initially been planted to provide preferred forage for grazing livestock. The majority of these forages are introduced, having originally come from areas in other continents. Most are now naturalized and are vital components of  pasture based grazing systems. Some common introduced forage species are tall fescue, orchard grass, red and white clover, and bermuda grass.   Some cropland and  pasture land has been converted to native warm season grasses such as switchgrass, bluestems, indiangrass, and gamagrass."
					onClick={
						(e) => {
							StateHookSvc.setSelectedLandUse(pastureButton);
							history.push('/explore');
						}
					}
				>
					<LandUseIcon landUseText={'Pastureland'}/>
					<p>Pastureland</p>
					<p className="studycount">{countHandling(pastureButton.studycount)}</p>
				</button> 
			
				<button 
					className="landuse-button-container"
					key={forestlandButton.landuse_id}
					value={forestlandButton.landuse_id}
					disabled={disableHandling(forestlandButton.studycount)}
					style={forestlandButton.studycount > 0 ? {'cursor': 'pointer'} : {'cursor': 'not-allowed'}}
					title="The NRCS characterizes forest land use as land with at least 10 percent cover by trees that will be at least 13 feet (4 meters) tall at maturity. The land also shows evidence of natural tree regeneration."
					onClick={
						(e) => {
							StateHookSvc.setSelectedLandUse(forestlandButton);
							history.push('/explore');
						}
					}
				>
					<LandUseIcon landUseText={'Forestland'}/>
					<p>Forestland</p>
					<p className="studycount">{countHandling(forestlandButton.studycount)}</p>
				</button> 
			
				<button 
					className="landuse-button-container"
					key={cropButton.landuse_id}
					value={cropButton.landuse_id}
					disabled={disableHandling(cropButton.studycount)}
					style={cropButton.studycount > 0 ? {'cursor': 'pointer'} : {'cursor': 'not-allowed'}}
					title="Cropland includes areas used for the production of adapted crops for harvest. Two subcategories of cropland are recognized: cultivated and non-cultivated. Cultivated cropland comprises land in row crops or close-grown crops and also other cultivated cropland, for example, hay land or pastureland that is in a rotation with row or close-grown crops. Non-cultivated cropland includes permanent hay land and horticultural cropland."
					onClick={
						(e) => {
							StateHookSvc.setSelectedLandUse(cropButton);
							history.push('/explore');
						}
					}
				>
					<LandUseIcon landUseText={'Cropland'}/>
					<p>Cropland</p>
					<p className="studycount">{countHandling(cropButton.studycount)}</p>
				</button>
		</div>
	)
}