import React, {useState, useEffect} from 'react';
import Buffering from '../Buffering/Buffering';
import {useHistory} from 'react-router-dom';
import Plot from 'react-plotly.js';
import * as d3 from 'd3-format';
import {useData} from '../../services/data_service';
import {useStateHooks} from '../../services/state_hook_service';
import {BsExclamation} from 'react-icons/bs';
import './PlotlyViolinTemplates.scss' 

export default function PlotlyViolinTemplate(props){
    const DataSvc = useData();
    const history = useHistory();
    const StateHookSvc = useStateHooks();
    const [initWidth, setInitWidth] = useState('100%');
    const [initHeight, setInitHeight] = useState('100%');

    // this is the formatting function for the rows and such
    function unpack(rows, key) {
        return rows.map(function(row) { return row[key]; });
    };

    useEffect(() => {
        DataSvc.fetchViolinData().then(res => {
            StateHookSvc.setShowBuffer(false);
            StateHookSvc.setViolinData(res);
        });
    }, [StateHookSvc.showNormData, 
        StateHookSvc.includeBenchmark, 
        StateHookSvc.includeControl,
        StateHookSvc.violinPlotTitle,
        StateHookSvc.selectedLandUse,
        StateHookSvc.uniqSecondViolinResources,
        StateHookSvc.selectedPracticeCode
    ]);

    // This useEffect hook was causing me issues but i've got it now
	useEffect(() => {
        setInitWidth(StateHookSvc.chartDivWidth)
	}, [StateHookSvc.chartDivWidth])

    useEffect(() => {
        setInitHeight(StateHookSvc.chartDivHeight)
	}, [StateHookSvc.chartDivHeight])


    function renderViolinPlot(){

        if(StateHookSvc.violinData == null && StateHookSvc.showBuffer == false){
            return <h3>No Results Found. Please update your search and try again.</h3>
        }else if(StateHookSvc.showBuffer == true){
            return <Buffering/>
        }else{
            // addingIcons()
            // StateHookSvc.setNoResultsFound(false);
            return <div  id="waldo" /*onClick={(e) => addingIcons(e)} */>
                {/* return <> */}
                {/* <span className="instance-click-icon"><BsExclamation onClick={() => instanceClick()}/></span> */}
                <Plot
                    data={StateHookSvc.violinData}
                    layout={layout}
                    config={config}
                    onClick={(e) => violinClick(e)}
                    // onClick={(e) => addingIcons(e)}
                    // onHover={(e) => addingIcons(e)}
                    // onUnhover={() => props.setShowViolinAxisTooltip(false)}
                    // onHover={(e) => {toolTipTake2(e, 'onHover')}}
                />
                {/* {addingIcons()} */}
                 </div>
        }
    };

    // function toolTipTake2(e, str){
    //     console.log('e', e)
    //     let legendLayer = d3.select('g.legend');
    //     let items = legendLayer.selectAll('g.traces');
    //     let tooltip;

    //     if(str == 'onHover'){
    //         console.log('onHover activate!');
    //         legendLayer.selectAll('.tooltip').remove();
    //         tooltip = legendLayer.append('text')
    //         .classed('tooltip', true)
    //         .text(e[0].trace.name)
    //     }
    //     else if(str == 'onUnhover'){
    //         if(tooltip){

    //         }
    //         tooltip.remove();
    //     }

    // }

    function renderTitle(){
        if(StateHookSvc.violinPlotTitle == 'Any'){
            return
        }
    }

    let config = {
        'displayModeBar': false,
        'modeBarButtonsToRemove': ['zoom2d', 'hoverCompareCartesian', 'hoverClosestCartesian', 'toggleSpikelines'], 
        // 'responsive': true 
    }
    let layout = {
        // title: `Percent Change for Resource Concerns that Include <br> ${StateHookSvc.violinPlotTitle == 'Any'?StateHookSvc.violinPlotTitle + ` Conservation Practice`:StateHookSvc.violinPlotTitle}`,
        showlegend: false,
        xaxis: {
            zeroline: false,
            range: StateHookSvc.showNormData == true ? [-5, 5] :[-125,200],
            title: {
                text: `${StateHookSvc.showNormData == true ? 'Difference in Normalized Values' : 'Relative Difference (%)'}`,
                font: {
                    family: 'Roboto, sans-serif',
                    size: '20'
                },
                pad: 0
            },
            fixedrange: true
        },
        margin: {
            autoexpand: true,
            t: 0,
            pad: 0
        },
        autosize: true,
        height: initHeight,
        width: initWidth,
        yaxis: {
            fixedrange: true,
            automargin: true,
            hoverformat: function(){
                console.log('good morning dave');
            }
        },
        // hovermode: "y"
        // yaxis100: {
        //     visible: true,
        //     anchor: 'y'
        // }
        // legend: {
        //     traceorder: 'reversed+legendgroup',
        //     x: -10,
        //     y: -1
        // },
        // violingap: 0,
        // violingroupgap: 0,
        // violinmode: "overlay",
    }

    function violinClick(e, idx){
        console.log('values', e, idx)
        for(let i = 0; i < StateHookSvc.unfilteredFirstViolinRes.length; i++){
            if(StateHookSvc.unfilteredFirstViolinRes[i].resource_component == e.points[0].y){
                StateHookSvc.setSelectedResourceComponent_id(StateHookSvc.unfilteredFirstViolinRes[i].rescomp_id);
                break;
            }
        }
        StateHookSvc.setSelectedResourceComponent(e.points[0].y);
        StateHookSvc.setNormData(false);
        StateHookSvc.setIncludeControl(true);
        StateHookSvc.setIncludeBenchmark(false);
        StateHookSvc.setNoResultsFound(false);
        let targetToolTip = StateHookSvc.firstViolinTooltips.filter((val) => val.resource_component === e.points[0].y);
        StateHookSvc.setResConTooltip(targetToolTip[0]);
    };

    function violinHover(e){
        // console.log('good morning, dave', e.points[0].y);
        //if we can get the axis abolve which it is hovering, then we're good to go!
        console.log('firing', e.points[0].y);
        props.setViolinAxisTooltip(e.points[0].y);
        props.setShowViolinAxisTooltip(true)
        // let target = document.getElementsByClassName('ytick');
        // console.log('target aquired', target);
    }

    function addingIcons(e){
        console.log('e',e);
        let target = document.querySelectorAll('ytick');
        // for(let i of target){
        //     if(i.textContent.includes())
        // }
        // console.log('yticks', target)
        // console.log('target', Object.values(target), Object.keys(target));
            // return Object.values(target).map((val, idx) => {
            //     console.log('val', val)
            //     let textDoc = document.createTextNode(`hello`);
            //     val.prepend(textDoc);
            //     //  WE CAN GET THE TEXT HERE: val.__data__.text
            //     return console.log('val', val.__data__.text)
            // })
    }

    return(
        <>
            {renderViolinPlot()}
        </>
    )
}