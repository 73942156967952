import React, {useState, createContext, useContext, useEffect} from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, useParams } from "react-router-dom";
import { FormatColorResetTwoTone } from "@mui/icons-material";
import config from '../config';

const StateHookContext = createContext(null);

export const StateHookProvider = ({children, ...services}) => {
    const [dataTableLabels, setDataTableLabels] = useState([]);
    const [selectedLandUse, setSelectedLandUse] = useState(null);
    const [selectedState, setSelectedState] = useState("");
    const [selectedPracticeCode, setSelectedPracticeCode] = useState(null);
    const [removeToggleButton, handleRemoveToggleButton] = useState(false);
    const [violinPlotTitle, setViolinPlotTitle] = useState(null);
    const [landUseArray, setLandUseArray] = useState([]);
    const [showBuffer, setShowBuffer] = useState(false);
    const [chartView, setChartView] = useState(false);
    const [stateList,setStateList] = useState({'label':'none','value':0});
    const [selectedResourceComponent,setSelectedResourceComponent] = useState(null);
    const [unfilteredViolinRes, setUnfilteredViolinRes] = useState([]);
    const [showNormData, setNormData] = useState(false);
    const [includeBenchmark, setIncludeBenchmark] = useState(false);
    const [includeControl, setIncludeControl] = useState(true);
    const [noResultsFound, setNoResultsFound] = useState(false);
    const [conservationDropdown, setConservationDropdown] = useState([]);
    const [totalStudyCount, setTotalStudyCount] = useState(0);
    const [totalObsCount, setTotalObsCount] = useState(0);
    const [totalWeightArr, setTotalWeightArr] = useState([]);
    const [uniqViolinResources, setUniqViolinResources] = useState([]);
    const [uniqSecondViolinResources, setUniqSecondViolinResources] = useState([]);
    const [chordChartOptions, setChordChartOptions] = useState({ title: { text: '' } });
    const [hideStateFilter, setHideStateFilter] = useState(false);
    const [selectedOutcome, setSelectedOutcome] = useState('');
    const [selectedSites, setSelectedSites] = useState('');
    const [mapView, setMapView] = useState(null);
    const [violinData, setViolinData] = useState([]);
    const [secondViolinData, setSecondViolinData] = useState([]);
    const [renderFinalTable, setRenderFinalTable] = useState(false);
    const [chartDivWidth, setChartDivWidth] = useState(500); // default 500px wide
    const [chartDivHeight, setChartDivHeight] = useState(500); // default 500px wide
    const [selectedResourceComponent_id, setSelectedResourceComponent_id] = useState(null);
    const [unfilteredSecondViolinRes, setUnfilteredSecondViolinRes] = useState([]);
    const [finalResultsTableVals, setFinalResultsTableVals] = useState(null);
    const [finalTableCells, setFinalTableCells] = useState([]);
    const [unfilteredFirstViolinRes, setUnfilteredFirstViolinRes] = useState([]);
    const [selectedDataElem_id, setSelectedDataElem_id] = useState(null);
    const [expandCitations, setExpandCitations] = useState(false);
    const [references, setReferences] = useState([]);
    const [studySiteCount, setStudySiteCount] = useState(0);
    const [chordArcClick, setChordArcClick] = useState(false);
    const [chordChartTooltips, setChordChartTooltips] = useState([]);
    const [showInterpretations, setShowInterpretations] = useState(false);
    const [firstViolinTooltips, setFirstViolinTooltips] = useState([]);
    //tooltip for the selected 
    const [conPracTooltip, setConPracTooltip] = useState(null);
    //tooltip for the selected resource concern from first violin plot
    const [resConTooltip, setResConTooltip] = useState(null);
    const [mapClick, setMapClick] = useState(false);
    // const [bandClick, setBandClick] = useState(false);

    const history = useHistory();
    function clearSearch(){
        setSelectedPracticeCode('');
        setViolinPlotTitle('');
        setSelectedResourceComponent('');
        setStateList({'label':'none','value':0});
        handleRemoveToggleButton(FormatColorResetTwoTone);
        setSelectedResourceComponent('');
        setNormData(false);
        setIncludeControl(true);
        setIncludeBenchmark(true);
        setNoResultsFound(false);
        setHideStateFilter(false);
        setStateList({'label':'none','value':0});
        setSelectedState('')
        setChordArcClick(false);
        history.push("/explore");
    }

    const value = {
        clearSearch: () => clearSearch(),
        setSelectedLandUse: (val) => setSelectedLandUse(val),
        setSelectedState: (val) => setSelectedState(val),
        setSelectedPracticeCode: (val) => setSelectedPracticeCode(val),
        handleRemoveToggleButton: (val) => handleRemoveToggleButton(val),
        setViolinPlotTitle: (val) => setViolinPlotTitle(val),
        setDataTableLabels: (val) => setDataTableLabels(val),
        setShowBuffer: (val) => setShowBuffer(val),
        setChartView: (val) => setChartView(val),
        setStateList: (val) => setStateList(val),
        setSelectedResourceComponent: (val) => setSelectedResourceComponent(val),
        setUnfilteredViolinRes: (val) => setUnfilteredViolinRes(val),
        setNormData: (val) => setNormData(val),
        setIncludeBenchmark: (val) => setIncludeBenchmark(val),
        setIncludeControl: (val) => setIncludeControl(val),
        setNoResultsFound: (val) => setNoResultsFound(val),
        setLandUseArray: (val) => setLandUseArray(val),
        setConservationDropdown: (val) => setConservationDropdown(val),
        setTotalStudyCount: (val) => setTotalStudyCount(val),
        setTotalObsCount: (val) => setTotalObsCount(val),
        setTotalWeightArr: (val) => setTotalWeightArr(val),
        setUniqViolinResources: (val) => setUniqViolinResources(val),
        setUniqSecondViolinResources: (val) => setUniqSecondViolinResources(val),
        setChordChartOptions: (val) => setChordChartOptions(val),
        setHideStateFilter: (val) => setHideStateFilter(val),
        setSelectedOutcome: (val) => setSelectedOutcome(val),
        setSelectedSites: (val) => setSelectedSites(val),
        setMapView:(val) => setMapView(val),
        setViolinData: (val) => setViolinData(val),
        setSecondViolinData: (val) => setSecondViolinData(val),
        setRenderFinalTable: (val) => setRenderFinalTable(val),
        setChartDivWidth: (val) => setChartDivWidth(val),
        setChartDivHeight: (val) => setChartDivHeight(val),
        setSelectedResourceComponent_id: (val) => setSelectedResourceComponent_id(val),
        setUnfilteredSecondViolinRes: (val) => setUnfilteredSecondViolinRes(val),
        setFinalResultsTableVals: (val) => setFinalResultsTableVals(val),
        setFinalTableCells: (val) => setFinalTableCells(val),
        setUnfilteredFirstViolinRes: (val) => setUnfilteredFirstViolinRes(val),
        setSelectedDataElem_id: (val) => setSelectedDataElem_id(val),
        setReferences: (val) => setReferences(val),
        setExpandCitations: (val) => setExpandCitations(val),
        setStudySiteCount: (val) => setStudySiteCount(val),
        setChordArcClick: (val) => setChordArcClick(val),
        setChordChartTooltips: (val) => setChordChartTooltips(val),
        setShowInterpretations: (val) => setShowInterpretations(val),
        setFirstViolinTooltips: (val) => setFirstViolinTooltips(val),
        setConPracTooltip: (val) => setConPracTooltip(val),
        setResConTooltip: (val) => setResConTooltip(val),
        setMapClick: (val) => setMapClick(val),
        // setBandClick: (val) => setBandClick(val),
        chartDivWidth: chartDivWidth,
        chartDivHeight: chartDivHeight,
        selectedLandUse: selectedLandUse,
        selectedPracticeCode: selectedPracticeCode,
        violinPlotTitle: violinPlotTitle,
        dataTableLabels: dataTableLabels,
        showBuffer: showBuffer,
        selectedState: selectedState,
        removeToggleButton: removeToggleButton,
        chartView: chartView,
        stateList: stateList,
        selectedResourceComponent: selectedResourceComponent,
        unfilteredViolinRes: unfilteredViolinRes,
        showNormData: showNormData,
        includeBenchmark: includeBenchmark,
        includeControl: includeControl,
        noResultsFound: noResultsFound,
        landUseArray: landUseArray,
        conservationDropdown: conservationDropdown,
        totalStudyCount: totalStudyCount,
        totalObsCount: totalObsCount,
        totalWeightArr: totalWeightArr,
        uniqViolinResources: uniqViolinResources,
        uniqSecondViolinResources: uniqSecondViolinResources,
        chordChartOptions: chordChartOptions,
        hideStateFilter: hideStateFilter,
        selectedOutcome: selectedOutcome,
        selectedSites: selectedSites,
        mapView: mapView,
        violinData: violinData,
        secondViolinData: secondViolinData,
        renderFinalTable: renderFinalTable,
        selectedResourceComponent_id: selectedResourceComponent_id,
        unfilteredSecondViolinRes: unfilteredSecondViolinRes,
        finalResultsTableVals: finalResultsTableVals,
        finalTableCells: finalTableCells,
        unfilteredFirstViolinRes: unfilteredFirstViolinRes,
        selectedDataElem_id: selectedDataElem_id,
        references: references,
        expandCitations: expandCitations,
        studySiteCount: studySiteCount,
        chordArcClick: chordArcClick,
        chordChartTooltips: chordChartTooltips,
        showInterpretations: showInterpretations,
        firstViolinTooltips: firstViolinTooltips,
        conPracTooltip: conPracTooltip,
        resConTooltip: resConTooltip,
        mapClick: mapClick
        // bandClick: bandClick
    };
    


    return(
        <StateHookContext.Provider value={value}>
            {children}
        </StateHookContext.Provider>
    );
}

export const useStateHooks = () => {
    return useContext(StateHookContext);
};