import React, { useRef, useEffect, useState } from 'react';
import MapView from "@arcgis/core/views/MapView";
import WebMap from "@arcgis/core/WebMap";
import Feature from "@arcgis/core/widgets/Feature";
import { useData } from '../../services/data_service';
import { useStateHooks } from '../../services/state_hook_service';
import FeatureFilter from "@arcgis/core/layers/support/FeatureFilter.js";
import Query from '@arcgis/core/rest/support/Query';
import Extent from '@arcgis/core/geometry/Extent';
import config from '../../config';
//import * as WatchUtils from "@arcgis/core/core/watchUtils";
import './Map.scss'

function Map(props) {
  const [mapStatus,setMapStatus] = useState('loading');
  const [center,setCenter] = useState();
  const [zoom,setZoom] = useState();
  const [mapView, setMapView] = useState(null);
  const [layers, setLayers] = useState(null);
  const [studySitesReady, setStudySitesReady] = useState(false);
  const [selRefId, setSelRefId] = useState(-1);
  const [mapDivStyle, setMapDivStyle] = useState(
    {
      padding: 0,
      margin: 0,
      opacity: '0%'
    }
  );
  const DataSvc = useData();
  const StateHookSvc = useStateHooks();
  const mapDiv = useRef(null);

  useEffect( () => {
    let mapReady = true;

    if (mapDiv.current) {
      /**
       * Initialize application
       */
      setCenter(props.center);
      setZoom(props.zoom);

      const webmap = new WebMap({
        portalItem: {
          id: process.env.REACT_APP_AGO_WEBMAP
        }
      });

      var view = new MapView(
        {
          container: mapDiv.current,
          map: webmap,
          center: center,
          zoom: zoom
        }
      );
      view.layerViews.on("change", function(event) {
        view.layerViews.forEach(lyr => {
          lyr.layer.outFields = ["*"];
          lyr.layer.popupEnabled = false;
        });
        if (event.added) {
          setLayers(view.layerViews);
        }
      });
      
      view.when().then(() => {
        if (mapReady) {
          StateHookSvc.setMapView(view);
          setMapStatus("ready");
          view.ui.move([ "zoom"], "bottom-left");

          if (view) {
            view.on("pointer-down", function(event) {
              if (view.layerViews)
              {
                const studySitesLayer = view.layerViews.find((layerView) => {return layerView.layer.title === "NRCS Study Locations"})
                const opts = {
                  include: studySitesLayer.layer
                };
                view.hitTest(event, opts).then((response) => {
                  if (response.results.length) {
                    document.getElementById("customPopupContainer").innerHTML = "";

                    const graphic = response.results[0].graphic;
                    const refId = response.results[0].graphic.attributes.ref_id;
                    setSelRefId(refId)
                    const feature = new Feature({
                      graphic: graphic,
                      view: view,
                      container: document.getElementById("customPopupContainer")
                    });
                    return feature;
                  }
                });
              }
            });
          }
          
        }
      });
    }
    return () => mapReady = false;
  },[]);

  useEffect(() => {
    setMapDivStyle(Object.assign({}, mapDivStyle, {"opacity": "100%"}));
    if (mapView) {
      props.setMapReady(true);
    }
  },[mapView,mapStatus,props]);

  useEffect(() => {
    if(layers) {
      if(layers.find((layerView) => {
      return layerView.layer.title === "NRCS Study Locations";
      })) {
        setStudySitesReady(true);
      }
      
    }
    
    
  },[layers]);

  useEffect(() => {
    if(selRefId > -1){
      DataSvc.getAbstract(selRefId)
      .then((res) => {
        return res.json()
      })
      .then((resJson) => {
        let abstract = resJson[0].get_abstract[0];
        let target = document.getElementById('abstract');
        while(target.firstChild){
          target.removeChild(target.firstChild)
        }
        let abstractText = document.createElement('p');
        abstractText.setAttribute('class', 'abstract-text')
        abstractText.innerHTML = `Abstract: ${abstract.abstract}`;
        target.appendChild(abstractText);
        StateHookSvc.setMapClick(true)
      })
      .catch((err) => console.log("err", err));
    }
  }, [selRefId])

  useEffect(() => {
		// https://developers.arcgis.com/javascript/latest/api-reference/esri-views-layers-FeatureLayerView.html#filter
    let siteFilter = new FeatureFilter({
			where: StateHookSvc.selectedSites
		});
		try {
			StateHookSvc.mapView.layerViews.find((layerView) => {
				return layerView.layer.title === "NRCS Study Locations";
			}).filter = siteFilter;
		}
		catch (ex) {
			console.log("Error filtering map", ex);
		}

	},
		[studySitesReady]
	);

  useEffect(() => {
		// https://developers.arcgis.com/javascript/latest/api-reference/esri-views-layers-FeatureLayerView.html#filter
		let siteFilter = new FeatureFilter({
			where: StateHookSvc.selectedSites

      // TODO: expand what goes into the 'where' here with the other data from the state hook svc file
		});
		try {
			StateHookSvc.mapView.layerViews.find((layerView) => {
				return layerView.layer.title === "NRCS Study Locations";
			}).filter = siteFilter;
      console.log('it did the first one! woo!', StateHookSvc.mapView)
      let query = new Query();

      query.where = StateHookSvc.selectedSites;
      console.log('query test', query);
      console.log('StateHookSvc.mapView.layerViews', StateHookSvc.mapView.layerViews);
      //  THIS IS THE PART THAT DOES THE LAYER HANDLING
      StateHookSvc.mapView.layerViews
      .find((layerView) => {
				return layerView.layer.title === "NRCS Study Locations";
			})
      //NOTE: this is commented out until further notice
      // .queryExtent(query).then(function(res){
      //   console.log('res.extent', res, res.extent);
      //   StateHookSvc.mapView.goTo(res.extent);
      // });
		}
		catch (ex) {
			console.log("Error filtering map", ex);
		}

	},
  // TODO: have remainining vars in clean up/rerender array for featurefilter re-rendering
		[StateHookSvc.selectedSites]
	);

  useEffect(() => {
    DataSvc.getCitations();
  }, [StateHookSvc.selectedLandUse, StateHookSvc.selectedPracticeCode, StateHookSvc.selectedResourceComponent_id, StateHookSvc.selectedDataElem_id]);

  return <div id="mapDiv" style={mapDivStyle} ref={mapDiv}></div>;
}

export default Map;