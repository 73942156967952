import React, {
	createRef,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";
import SplitPaneContext from "./SplitPaneContext";

// import { useHistory } from "react-router-dom";
// import { FormatColorResetTwoTone } from "@material-ui/icons";
import { useStateHooks } from "../../services/state_hook_service";
import { useData } from "../../services/data_service";
import './SliderPane.scss';
//-----------------------------------------------------------------------------
// SplitPane component
//-----------------------------------------------------------------------------
const SplitPane = ({ children, ...props }) => {

	const DataSvc = useData();
	const StateHookSvc = useStateHooks();
	const yDividerPos = useRef(null);
	const xDividerPos = useRef(null);
	const thisNodeRef = useRef();
	// This sets the default height of the 1st Child Node (top left)
	const [clientHeight, setClientHeight] = useState(400);
	// const [clientHeight, setClientHeight] = useState(300);
	// This sets the default width of the 1st Child Node (top left)
	// const [clientWidth, setClientWidth] = useState(730);
	const [clientWidth, setClientWidth] = useState(450);
	// const [clientWidth, setClientWidth] = useState('100%');
	
	const onMouseHoldDown = (e) => {
		yDividerPos.current = e.clientY;
		xDividerPos.current = e.clientX;
	};
	
	const onMouseHoldUp = () => {
		yDividerPos.current = null;
		xDividerPos.current = null;
	};
	
	const onMouseHoldMove = (e) => {
		if (!yDividerPos.current && !xDividerPos.current) {
			return;
		}
		if (props.splitdirection == "updown") {
			if (clientHeight + e.clientY - yDividerPos.current < 100) {
				return;
			}
			if (clientHeight + e.clientY - yDividerPos.current > (thisNodeRef.current.clientHeight - 100)) {
				return;
			}
			setClientHeight(clientHeight + e.clientY - yDividerPos.current);
			yDividerPos.current = e.clientY;
			setClientWidth(clientWidth + e.clientX - xDividerPos.current);
			xDividerPos.current = e.clientX;
		}

		if (props.splitdirection == "leftright") { 
			if (clientWidth + e.clientX - xDividerPos.current < 200) {
				return;
			}
			if (clientWidth + e.clientX - xDividerPos.current > (thisNodeRef.current.clientWidth - 650)) {
				return;
			}
			setClientWidth(clientWidth + e.clientX - xDividerPos.current);
			xDividerPos.current = e.clientX;
			setClientHeight(clientHeight + e.clientY - yDividerPos.current);
			yDividerPos.current = e.clientY;
			StateHookSvc.setChartDivWidth(window.outerWidth - clientWidth);
		}
	};
	
	useEffect(() => {
		document.addEventListener("mouseup", onMouseHoldUp);
		document.addEventListener("mousemove", onMouseHoldMove);
		
		return () => {
			document.removeEventListener("mouseup", onMouseHoldUp);
			document.removeEventListener("mousemove", onMouseHoldMove);
		};
	});
	
	useEffect(() => {
		StateHookSvc.setChartDivWidth(window.outerWidth - clientWidth);
	}, [])


	useEffect(() => {
		// Do we need to react to a change in the div height here? Prob not.
	},[StateHookSvc.chartDivHeight])

	return (
		<div ref={thisNodeRef} {...props}>
			<SplitPaneContext.Provider
				value={{
					clientHeight,
					setClientHeight,
					clientWidth,
					setClientWidth,
					onMouseHoldDown,
				}}
			>
				{children}
			</SplitPaneContext.Provider>
		</div>
	);
};
//-----------------------------------------------------------------------------
// Dividing line
//-----------------------------------------------------------------------------
export const Divider = (props) => {
	const { onMouseHoldDown } = useContext(SplitPaneContext);
	
	return <div onMouseDown={onMouseHoldDown} className={props.class}>
		<div className={props.class + "_handle"}/>
	</div>;
};
	
//-----------------------------------------------------------------------------
// Left half container
//-----------------------------------------------------------------------------
export const SplitPaneLeft = (props) => {
	const topRef = createRef();
	const { clientWidth, setClientWidth } = useContext(SplitPaneContext);
	
	useEffect(() => {
		if (!clientWidth) {
			setClientWidth(topRef.current.clientWidth / 2);
			return;
		}
		topRef.current.style.minWidth = clientWidth + "px";
		topRef.current.style.maxWidth = clientWidth + "px";
	}, [clientWidth]);
	
	return(
		<div className="split-pane-left" ref={topRef}>
			{props.children}
		</div>
	)
};
//-----------------------------------------------------------------------------
// Top component - Display Child 1/3
//-----------------------------------------------------------------------------
export const SplitPaneTop = (props) => {
	const topRef = createRef();
	const { clientHeight, setClientHeight } = useContext(SplitPaneContext);
	const StateHookSvc = useStateHooks();
	
	useEffect(() => {
		if (!clientHeight) {
			setClientHeight(topRef.current.clientHeight / 2);
			return;
		}
		topRef.current.style.minHeight = clientHeight + "px";
		topRef.current.style.maxHeight = clientHeight + "px";
	}, [clientHeight]);
	
	return (
		<div className="split-pane-top" ref={topRef}>
			{props.postInitialRender ? props.children : null}
		</div>
	);
};
//-----------------------------------------------------------------------------
// Right half of slider - Display Child 2/3
//-----------------------------------------------------------------------------
export const SplitPaneRight = (props) => {
	const clientWidth = useContext(SplitPaneContext);
	const StateHookSvc = useStateHooks();
	const chartHeightOffset = 120;
	const thisNodeRef = useRef(null);
	const [thisNodeHeight, setThisNodeHeight] = useState(200);
	useEffect(() => {
		 setThisNodeHeight(thisNodeRef.current.clientHeight - chartHeightOffset);
		 StateHookSvc.setChartDivHeight(thisNodeRef.current.clientHeight - chartHeightOffset);
	},[thisNodeRef])
	return (
		<div ref={thisNodeRef} {...props} className="split-pane-right" style={{'width': clientWidth}} clientHeight={thisNodeHeight}>
			{props.children}
		</div>
	);
};
//-----------------------------------------------------------------------------
// Bottom half of left side - Display Child 3/3
//-----------------------------------------------------------------------------
export const SplitPaneBottom = (props) => {  
	const [showAbstract, setShowAbstract] = useState(false);
	const StateHookSvc = useStateHooks();
	const DataSvc = useData();
	
	useEffect(() => {
		if(showAbstract == true){
			setShowAbstract(false);
			let target = document.getElementById('abstract');
			while(target.firstChild){
				target.removeChild(target.firstChild);
			}
		}
		if(StateHookSvc.mapClick == true){
			let target = document.getElementById("customPopupContainer");
			while(target.firstChild){
				target.removeChild(target.firstChild);
			}
			// target.appendChild(<div>{props.children}</div>)
		}
		StateHookSvc.setMapClick(false);
	}, [
		StateHookSvc.landUseArray,
        StateHookSvc.dataTableLabels,
        StateHookSvc.conservationDropdown,
        StateHookSvc.totalWeightArr,
        StateHookSvc.selectedLandUse,
        StateHookSvc.selectedPracticeCode,
        StateHookSvc.selectedResourceComponent_id,
        StateHookSvc.selectedDataElem_id
	])

	return (
		<>
			<div {...props} className="split-pane-bottom">
				<div className="split-pane-bottom-tabs">
					<div style={StateHookSvc.mapClick == true ? null : {'display': 'none'}} className="split-tab" id={showAbstract == false ? 'selected-tab' : null} onClick={() => setShowAbstract(!showAbstract)}>
						<p>Study Site</p>
					</div>
					<div style={StateHookSvc.mapClick == true ? null : {'display': 'none'}} className="split-tab" id={showAbstract == true ? 'selected-tab' : null} onClick={() => setShowAbstract(!showAbstract)}>
						<p>Abstract</p>	
					</div>
					
				</div>
				<div style={showAbstract == true ? {'display': 'none'}: null}>
					{props.children}
				</div>
				<div id="abstract" style={showAbstract == true ? null: {'display': 'none'}}></div>
				
			</div>
		</>
	);
};
	
	
export default SplitPane;