import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Redirect, Route} from "react-router-dom";

import Home from './components/HomePage/HomePage';
import Explorer from './components/Explorer/Explorer';
import PageNotFound from './components/PageNotFound/PageNotFound';
import FAQ from './components/FAQ/FAQ';
import About from './components/AboutPage/AboutPage';
import LinkHeader from './components/Link-Header/Link-Header';
import {useStateHooks} from './services/state_hook_service';
import SelectDropdownBreadcrumbs from './components/Select_Dropdown_Breadcrumbs/SelectDropdownBreadcrumbs'
import './App.scss';

function App(props) {
	const StateHookSvc = useStateHooks();
	const appClassHome = "app-container";
	const appClassExplore = "app-container-explore"
	return (
		<>
			<div className={((window.location.pathname == "/")||(window.location.pathname == "/home"))?appClassHome:appClassExplore}>
				<LinkHeader />
				<Switch>
					<Route exact path="/" render={() => { return (<Redirect to="/home" />) }} />
					<Route exact path="/home" children={routeProps => (
						<div className="main-content-wrapper" style={{}}>
							<Home/>
						</div>
					)}>
					</Route>
					<Route exact path="/explore" children={routeProps => {
						if (StateHookSvc.selectedLandUse) {
							return (
								<div className="main-content-wrapper">
									<Explorer/>
								</div>								
							)
						}
						else {
							return (<Redirect to="/home" />)
						}
					}} />
					<Route exact path="/explore/:selectedChart" children={routeProps => {
						if (StateHookSvc.selectedLandUse) {
							return (

								<div className="main-content-wrapper" style={{}}>
									<Explorer/>
								</div>
							)
						}
						else {
							return (<Redirect to="/home" />)
						}
					}} />
					<Route exact path="/explore/:selectedChart/:violin1" children={routeProps => {
						if (StateHookSvc.selectedLandUse) {
							return (

								<div className="main-content-wrapper" style={{}}>
									<Explorer/>
								</div>
							)
						}
						else {
							return (<Redirect to="/home" />)
						}
					}} />
					<Route exact path="/explore/:selectedChart/:violin1/:violin2" children={routeProps => {
						if (StateHookSvc.selectedLandUse) {
							return (

								<div className="main-content-wrapper" style={{}}>
									<Explorer/>
								</div>
							)
						}
						else {
							return (<Redirect to="/home" />)
						}
					}} />
					<Route exact path="/explore/table-results" children={routeProps => {
						if(StateHookSvc.selectedLandUse){
							return(
								<div className="main-content-wrapper" style={{}}>
									<Explorer/>
								</div>
							)
						}
						
					}} />
					<Route exact path="/faq" children={routeProps => (
						<div className="main-content-wrapper" style={{}}>
							<FAQ />
						</div>
					)} />
					<Route exact path="/about" children={routeProps => (
						<div className="main-content-wrapper" style={{}}>
							<About />
						</div>
					)} />
					<Route exact path="/workshop">
						<SelectDropdownBreadcrumbs/>
					</Route>
					<Route component={PageNotFound} />
				</Switch>
			</div>
		</>
	);
}

export default App;
