import React, { useState, useEffect } from 'react';
import HighCharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import dependencywheel from 'highcharts/modules/dependency-wheel';
import sankey from 'highcharts/modules/sankey';
import Buffering from '../Buffering/Buffering';
import { useHistory } from 'react-router-dom';
import {useData} from '../../services/data_service';
import {useStateHooks} from '../../services/state_hook_service';
import './Chord-Chart-Template.scss';

sankey(HighCharts)
dependencywheel(HighCharts)

export default function ChartTemplate(props) {
	const history = useHistory();
	const DataSvc = useData();
	const StateHookSvc = useStateHooks();
	const [chordChartOptions, setChordChartOptions] = useState({ title: { text: '' } });
	const [selPracCode, setSelPracCode] = useState(null);
	const [chartObject, setChartObject] = useState(null);

	function handleChart() {
		//code to fix bug in the source code, but was resolved in later update; DO NOT DELETE!!!!! - CA 5/4/23
		// (function (H) {
		// 	var animObject = H.animObject;
		// 	H.seriesTypes.dependencywheel.prototype.animate = function (init) {
		// 		if (!init) {
		// 			var duration = animObject(this.options.animation).duration,
		// 				step = (duration / 2) / this.nodes.length;
		// 			this.nodes.forEach(function (point, i) {
		// 				var graphic = point.graphic;
		// 				if (graphic) {
		// 					graphic.attr({ opacity: 0 });
		// 					setTimeout(function () {
		// 						if (graphic && graphic.renderer) {
		// 							graphic.animate({ opacity: 1 }, { duration: step });
		// 						}
		// 					}, step * i);
		// 				}
		// 			}, this);
		// 			this.points.forEach(function (point) {
		// 				var graphic = point.graphic;
		// 				if (!point.isNode && graphic) {
		// 					graphic.attr({ opacity: 0 })
		// 						.animate({
		// 							opacity: 1
		// 						}, this.options.animation);
		// 				}
		// 			}, this);
		// 		}
		// 	};
		// }(HighCharts));
		return (
			<>
				{<HighchartsReact
					containerProps={{ style: { height: "calc(100% - 50px)", width: "100%" } }}
					highcharts={HighCharts}
					constructorType={'chart'}
					options={chordChartOptions}
				/>}
			</>
		)
	}

	function nullCheck(){
		if(chordChartOptions == null){
			return <h3>No results found. Please update your search parameters and try again.</h3>
		}else{
			return chartObject
		}
	}


	useEffect(() => {
		return chartObject;
	})

	useEffect(() => {
		StateHookSvc.handleRemoveToggleButton(false);
		setChartObject(handleChart());
	},[chordChartOptions])

	// This useEffect hook was causing me issues but i've got it now
	useEffect(() => {
		if (!(StateHookSvc.showBuffer == true) && chartObject) {
			chartObject.props.children.props.highcharts.charts.map(chart => {
				if (chart) chart.reflow();
			})
		} 
	}, [StateHookSvc.chartDivWidth])

	useEffect(() => {
		// the history has to be passed into the chord chart to push users to the violin plot
		let historyToPush = history

		DataSvc.fetchChordChartData(historyToPush).then((res) => {
			setChordChartOptions(res)
		});
	}, [StateHookSvc.selectedLandUse, StateHookSvc.selectedState]);


	return (
		<div style={{width: '100%', height: '100%'}}>
			{StateHookSvc.showBuffer == true ? <Buffering/> : nullCheck()}
		</div>

	);
};