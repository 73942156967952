import React, { useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import Buffering from '../Buffering/Buffering';
import { useData } from '../../services/data_service';
import {useStateHooks} from '../../services/state_hook_service';
import './DataTableTemplate.scss';

export default function DataTableTemplate(props){
    const DataSvc = useData();
    const StateHookSvc = useStateHooks();

    function renameKeys(){
        //rename the keys here to match desired headers
        formattedRows = StateHookSvc.dataTableLabels.map(item => {
            //console.log('item', item.practname);
            return { 'Practice Name': item.practname, 'Study Count': item.studycount, 'Practice Code': item.practcode };
        })
        return formattedRows
    };
    let tableBodyHeightVal = (StateHookSvc.chartDivHeight - 100) + "px";
    let formattedRows;
    renameKeys();
    const dataTable = {
        columns: ['Practice Name','Study Count', 'Practice Code'],
        data: formattedRows,
        options: {
            print: false,
            download: false,
            expandableRowsHeader: false,
            filter: false,
            pagination: false,
            selectableRowsOnClick: false,
            tableBodyHeight: tableBodyHeightVal
        }
    }
    useEffect(() => {
        DataSvc.fetchListTableData();
        // return () => {
        //     StateHookSvc.setDataTableLabels([]);
        // }
    }, []);

    return(
        <div id="data-table-container">
            {StateHookSvc.showBuffer == true? 
            <Buffering/> : 
            <MUIDataTable
                title="Table of Dummy Data"
                data={dataTable.data}
                columns={dataTable.columns}
                options={dataTable.options}
            />}
        </div>
    )
}