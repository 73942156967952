import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, useParams } from "react-router-dom";
import Select from 'react-select';
import PlotlyViolinTemplate from '../Chart/PlotlyViolinTemplate';
import PlotlyViolinTwoTemplate from '../Chart/PlotlyViolinTwoTemplate';
import ChordChartTemplate from '../Chart/Chord-Chart-Template';
import DataTableTemplate from '../Chart/DataTableTemplate';
import {useData} from '../../services/data_service';
import {useStateHooks} from '../../services/state_hook_service';
import {BsQuestion} from 'react-icons/bs';
import {BsInfo} from 'react-icons/bs';
import ResultsTable from './ResultsTable';
import './ChartWrapper.scss';

function ChartWrapper(props) {
    
    const DataSvc = useData();
    const StateHookSvc = useStateHooks();
    // const clientWidth = useContext(SplitPaneContext);
  
    useEffect(() => {}, [StateHookSvc.stateList])
    let { selectedChart = 'chord' } = useParams();
	
	const [postInitialRender, handlePostInitialRender] = useState(false);
	const [showToolTip_Chord, setShowToolTip_Chord] = useState(false);
	const [landUseLink, setLandUseLink] = useState('');
	const [showToolTip_ViolinFilter, setShowToolTip_ViolinFilter] = useState(false);
	const [toolTipText_ViolinFilter, setToolTipText_ViolinFilter] = useState('');
	const [stateStr, setStateStr] = useState(''); 
	const [violinAxisTooltip, setViolinAxisTooltip] = useState('');
	const [showViolinAxisTooltip, setShowViolinAxisTooltip] = useState(false);
	const [chordChartTooltip, setChordChartTooltip] = useState('');
	const [showChordChartTooltip, setShowChordChartTooltip] = useState(false);


    function dataSelectionHandling(val){
        // StateHookSvc.setShowBuffer(true);
        if (val === 'real'){
          // do hooks here
          StateHookSvc.setNormData(false);
        //   props.setShowRealData(true);
        }
        else if (val === 'norm'){
          // hook handling
          StateHookSvc.setNormData(true);
        } 
        else if (val == 'all'){
        //   StateHookSvc.setNormData(true);
          StateHookSvc.setIncludeBenchmark(true);
          StateHookSvc.setIncludeControl(true);
        }
        else if (val == 'treatcontrol'){
          // when user clicks on this, include the controls
        //   StateHookSvc.setNormData(true);
          StateHookSvc.setIncludeBenchmark(false);
          StateHookSvc.setIncludeControl(true);
        }
        else if (val == 'benchmark'){
        //   StateHookSvc.setNormData(false);
          StateHookSvc.setIncludeBenchmark(true);
          StateHookSvc.setIncludeControl(false);
        }
      };

	  useEffect(() => {
        if(StateHookSvc.selectedLandUse.landuse == 'Rangeland'){
            setLandUseLink('https://www.nrcs.usda.gov/wps/portal/nrcs/detailfull/national/landuse/rangepasture/range/?cid=STELPRDB1043345')
        } else if(StateHookSvc.selectedLandUse.landuse == 'Pastureland'){
            setLandUseLink('https://www.nrcs.usda.gov/wps/portal/nrcs/main/national/landuse/rangepasture/pasture/')
        }else if(StateHookSvc.selectedLandUse.landuse == 'Forestland'){
            setLandUseLink('https://www.nrcs.usda.gov/wps/portal/nrcs/main/national/landuse/forestry/')
        }else if(StateHookSvc.selectedLandUse.landuse == 'Cropland'){
            setLandUseLink('https://www.nrcs.usda.gov/wps/portal/nrcs/main/national/landuse/crops/')
        }
    }, [StateHookSvc.selectedLandUse]);
	
	function displayData() {
        if (StateHookSvc.renderFinalTable == true) {
			// this is where the final table component will go
			return(
				<>
					<ResultsTable/>
				</>
			)   
        }
		else if (selectedChart == 'chord') {
			if (StateHookSvc.chartView == false) {
				return (
					<div className="outer-highcharts-container">
						<span id="chord-chart-question" onClick={() => {setShowToolTip_Chord(!showToolTip_Chord)}}><BsQuestion/></span>
						<div className="chord-chart-tooltip-container" style={showToolTip_Chord ? null : {display: 'none'}}>
							<p>
								The diagram represents the number of studies available that are related to one or more NRCS conservation practices for the selected land use. The width of the band on the outside of the circle represents the total number of studies pertaining to a unique conservation practice. The band connecting practices represents the number of studies pertaining to the combination of practices. To view data for a single conservation practice, select a conservation practice from the outer-most band or the dropdown. To view data for a combination of practices, select a band between two practices.<span><a href="https://www.nrcs.usda.gov/resources/guides-and-instructions/conservation-practice-standards" target="_blank">Link to NRCS Conservation Practice Standards</a></span>
							</p>
						</div>
						<ChordChartTemplate/>
					</div>
				)
			}
			else {
				return (
					<div className="outer-highcharts-container">
						<DataTableTemplate/>
					</div>
				)
			}
		}
		else if (selectedChart == 'violin') {
			return (
				<div className="outer-plotly-container">
					{StateHookSvc.selectedResourceComponent ? 
					<>
						<PlotlyViolinTwoTemplate 
							chartDivWidth={props.chartDivWidth}
							setChartDivWidth={props.setChartDivWidth}
							setShowViolinAxisTooltip={setShowViolinAxisTooltip}
							setViolinAxisTooltip={setViolinAxisTooltip}
						/>
					</>
					:
					<>
					{/* <span className="instance-click-icon"><BsExclamation onClick={() => instanceClick()}/></span> */}
						<PlotlyViolinTemplate
							chartDivWidth={props.chartDivWidth}
							setChartDivWidth={props.setChartDivWidth}
							// setViolinAxisTooltip={setViolinAxisTooltip}
							// setShowViolinAxisTooltip={setShowViolinAxisTooltip}
							// showViolinAxisTooltip={showViolinAxisTooltip}
							setShowToolTip_ViolinFilter={setShowToolTip_ViolinFilter}
							setToolTipText_ViolinFilter={setToolTipText_ViolinFilter}
							showToolTip_ViolinFilter={showToolTip_ViolinFilter}
						/> 
						{/* {showViolinAxisTooltip ? <p className="violin-tooltip">{violinAxisTooltip}</p> : null} */}
					</>
					}
				</div>
			)
		}
	}

	function instanceClick(){
        // console.log('testing the thingy');
        StateHookSvc.setShowInterpretations(!StateHookSvc.showInterpretations);
    };

	function violinTitleTooltip(val){
		// console.log('val', val)
		if(showViolinAxisTooltip){
			setShowViolinAxisTooltip(false);
			setViolinAxisTooltip('');
		}else{
			setShowViolinAxisTooltip(true);
			let target = StateHookSvc.firstViolinTooltips.filter((item) => item.resource_component === val);
			// console.log('target', target);
			setViolinAxisTooltip(val + ' : ' + target[0].tooltip);
		}

	}

	function toggleChordChartTooltip(val){
		// console.log('val', val)
		if(chordChartTooltip){
			setShowChordChartTooltip(false);
			setChordChartTooltip('');
		}else{
			setShowChordChartTooltip(true);
			let target = StateHookSvc.chordChartTooltips.filter((item) => item.practname === val);
			// console.log('target', target);
			setChordChartTooltip(val + ' : ' + target[0].pract_tooltip);
		}

	}

	function handleViolinTitles(){
		if(selectedChart == 'violin' && StateHookSvc.selectedResourceComponent){
			return <>
				<p style={showChordChartTooltip ? null : {display: 'none'}} className="violin-tooltip-1">{chordChartTooltip}</p>
				<p style={showViolinAxisTooltip ? null : {display: 'none'}} className="violin-tooltip-1">{violinAxisTooltip}</p>
				<p className="title">Relative Difference for Treatments that Include <span className="bold" onClick={() => toggleChordChartTooltip(StateHookSvc.violinPlotTitle)}>{StateHookSvc.violinPlotTitle == 'Any' ? StateHookSvc.violinPlotTitle + ` Conservation Practice` : StateHookSvc.violinPlotTitle}</span> on <span className="bold" onClick={() => violinTitleTooltip(StateHookSvc.selectedResourceComponent)}>{StateHookSvc.selectedResourceComponent == 'Any' ? StateHookSvc.selectedResourceComponent + ' Selected Resource Component' : StateHookSvc.selectedResourceComponent}</span></p>
				<p className="violin-filter-tooltip-text" style={showToolTip_ViolinFilter ? null : {display: 'none'}}>{toolTipText_ViolinFilter}</p>
			</>
		} else if (selectedChart == 'violin' && !StateHookSvc.selectedResourceComponent){
			return <>
				<p style={showChordChartTooltip ? null : {display: 'none'}} className="violin-tooltip-1">{chordChartTooltip}</p>
				<p className="title">Relative Difference for Resource Concerns that Include <span className="bold" onClick={() => toggleChordChartTooltip(StateHookSvc.violinPlotTitle)}>{StateHookSvc.violinPlotTitle == 'Any' ? StateHookSvc.violinPlotTitle + ` Conservation Practice` : StateHookSvc.violinPlotTitle}</span></p>
				<p className="violin-filter-tooltip-text" style={showToolTip_ViolinFilter ? null : {display: 'none'}}>{toolTipText_ViolinFilter}</p>
			</>
		}else{
			return null
		}
	};

	function handleViolinTooltips(str){
		if(showToolTip_ViolinFilter == false){
			setShowToolTip_ViolinFilter(true);
			// console.log ('1');
			if(str == 'control'){
				setStateStr(str);
				return setToolTipText_ViolinFilter(`Relative Difference from Control (%) = ((Treatment value - Control value)/Treatment value)*100 where the treatment and control measurements occurred on the same study site. This reduced the effects caused by climate, insects, disease or factors during the study.`);
			}else if(str == 'pre-treatment'){
				setStateStr(str);
				return setToolTipText_ViolinFilter(`Relative Difference from Pre-Treatment (%) = ((Treatment value - Pre-treatment value)/Treatment value)*100 where the treatment and pre-treatment measurements occurred on the same study site. Factors such as climate or site disturbances may cause some of the observed change.`);
			}else if(str == 'normalized'){
				setStateStr(str);
				return setToolTipText_ViolinFilter(`Relative difference calculations were performed on normalized values where the normalized value is calculated by subtracting the average of the values across studies for the selected practice  from the measured value and dividng the difference by the average.`);
			}else if(str == 'measured'){
				setStateStr(str);
				return setToolTipText_ViolinFilter(`Relative Difference calculations were performed on the data as they were presented in the study with no additional transformation.`);
			}
		}
		else if(showToolTip_ViolinFilter == true && stateStr !== str){
			// console.log ('2', str, toolTipText_ViolinFilter);
			if(str == 'control'){
				setStateStr(str);
				return setToolTipText_ViolinFilter(`Relative Difference from Control (%) = ((Treatment value - Control value)/Treatment value)*100 where the treatment and control measurements occurred on the same study site. This reduced the effects caused by climate, insects, disease or factors during the study.`);
			}else if(str == 'pre-treatment'){
				setStateStr(str);
				return setToolTipText_ViolinFilter(`Relative Difference from Pre-Treatment (%) = ((Treatment value - Pre-treatment value)/Treatment value)*100 where the treatment and pre-treatment measurements occurred on the same study site. Factors such as climate or site disturbances may cause some of the observed change.`);
			}else if(str == 'normalized'){
				setStateStr(str);
				return setToolTipText_ViolinFilter(`Relative difference calculations were performed on normalized values where the normalized value is calculated by subtracting the average of the values across studies for the selected practice  from the measured value and dividng the difference by the average.`);
			}else if(str == 'measured'){
				setStateStr(str);
				return setToolTipText_ViolinFilter(`Relative Difference calculations were performed on the data as they were presented in the study with no additional transformation.`);
			}
		}
		else{
			console.log('3')
			setShowToolTip_ViolinFilter(false);
			setToolTipText_ViolinFilter('');
		}
	}

    return (
		<>
			{StateHookSvc.violinPlotTitle && !StateHookSvc.selectedResourceComponent ? <span className="instance-click-icon"><BsInfo onClick={() => instanceClick()}/></span> : null}
			{handleViolinTitles()}
			<div className="ChartWrapper">
				
				<div className="hide">
					<div className="selectDropdown" style={{ "width": "200px" }}>
						<Select className="stateDropdown"
						styles={{
							// Fixes the overlapping problem of the component
							menu: provided => ({ ...provided, zIndex: 9999 })
						}}
						// value commented out until state fetch is resolved
						options={StateHookSvc.stateList}
						isClearable={props.isSelectClearable}
						isLoading={props.isSelectLoading}
						onChange={(item) => {
							if (!item) StateHookSvc.setSelectedState({ "value": null, "label": "none" });
							if (item && item.value != 0) {
								console.log('item', item)
								return StateHookSvc.setSelectedState(item)
							};
						}} 
					/>
					</div>
				</div>
				{StateHookSvc.selectedPracticeCode !== null && StateHookSvc.renderFinalTable == false?
						<div className="clear-filter">
								<div className="filter-violin-data" >
									<div>						
										<label htmlFor="meas-val">Measured Values <span className="violin-filter-tooltip" onClick={() => handleViolinTooltips('measured')}><sup><BsQuestion/></sup></span></label>
										<input type="radio" name="data-type" value="real-values" id="meas-val"
										onClick={() => dataSelectionHandling('real')} 
										checked={!StateHookSvc.showNormData} 
										onChange={() => {return;}}/>
									</div>
									<div>
										<label htmlFor="norm-val">Normalized Values <span className="violin-filter-tooltip" onClick={() => handleViolinTooltips('normalized')}><sup><BsQuestion/></sup></span></label> 
										<input type="radio" name="data-type" value="normal-values" id="norm-val"
										onClick={() => dataSelectionHandling('norm')} 
										checked={StateHookSvc.showNormData} 
										onChange={() => {return;}}/>
									</div>
								</div>
								<div className="filter-violin-data">
									<div>
										<label htmlFor="rel-diff-control">Relative Difference from Control <span className="violin-filter-tooltip" onClick={() => handleViolinTooltips('control')}><sup><BsQuestion/></sup></span></label>
										<input type="radio" name="data-filter" value="control" id="rel-diff-control"
										onClick={() => dataSelectionHandling('treatcontrol')} 
										checked={StateHookSvc.includeControl && !StateHookSvc.includeBenchmark} 
										onChange={() => {return;}}/>
									</div>
									<div>
										<label htmlFor="rel-diff-pre-treatment">Relative Difference from Pre-Treatment <span className="violin-filter-tooltip" onClick={() => handleViolinTooltips('pre-treatment')}><sup><BsQuestion/></sup></span></label>
										<input type="radio" name="data-filter" value="pre-treatment" id="rel-diff-pre-treatment"
										onClick={() => dataSelectionHandling('benchmark')} 
										checked={StateHookSvc.includeBenchmark && !StateHookSvc.includeControl} 
										onChange={() => {return;}}/>
									</div>
								</div>
						</div>
						: 
						null 
					}
				{displayData()}
				{/* <div className="chart-view-buttons">
					{StateHookSvc.removeToggleButton ? null : 
						<button onClick={() => props.handleChartView(!props.chartView)} id="toggle-chord-chart-button">Toggle List View</button>
					}
				</div> */}
			</div>
        </>
	);
};

export default ChartWrapper;