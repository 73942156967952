import React from 'react';
import {Link} from 'react-router-dom';
import './Link-Header.scss';
import Logo from '../../images/core_logo_small_v0.1.png';
import { useStateHooks } from '../../services/state_hook_service';
import { useHistory } from 'react-router-dom';
import StoneLogo from '../../images/stone-usda-logo';
// import USDALogo from '../../images/usda-logo.png'

export default function LinkHeader(){
    const StateHookSvc = useStateHooks();
    const history = useHistory();

    function pushToHomePage(){
        StateHookSvc.setViolinPlotTitle('');
        StateHookSvc.setSelectedResourceComponent('');
        StateHookSvc.setSelectedPracticeCode(null);
        StateHookSvc.setNormData(false);
        StateHookSvc.setIncludeControl(true);
        StateHookSvc.setIncludeBenchmark(true);
        // StateHookSvc.setNoResultsFound(false);
        StateHookSvc.setStateList({'label':'none','value':0});
        StateHookSvc.setRenderFinalTable(false);
        StateHookSvc.setChordArcClick(false);
        StateHookSvc.setSelectedResourceComponent_id(null);
        StateHookSvc.setConPracTooltip(null);
        StateHookSvc.setMapClick(false);
        history.push('/home');
    };

    return(
        <div className="Link-Container">
            <section className="title-header">
                <div className="title-text" onClick={() => pushToHomePage()}>
                    {/* <img src={USDALogo} alt="USDA_Logo" className="usda-logo"/> */}
                    <img src={StoneLogo} alt="Stone_ENV_Logo" className="stone-logo"/>
                    <p className="core-text"><span>C</span>onservation <span>O</span>utcomes <span>R</span>esearch <span>E</span>xplorer</p>
                    <p className="pairing-science">Pairing Science with Conservation, Developed for CEAP Grazing Lands</p>
                </div>
            </section>
            <section className="links-themselves">
                {/* <Link to="/home" id="home-link" onClick={() => pushToHomePage()}>Find a Study</Link> */}
                <Link to="/add" style={{display: 'none'}}>Enter Data</Link>
                <Link to='/about' style={{display: 'none'}}>About</Link>
                <Link to="/faq" style={{display: 'none'}}>FAQs</Link>
            </section>
            
        </div>
    )
}