import React from 'react';
import './Buffering.scss';

export default function Buffering(props){
    return(
        <>
            <div id="spinner-container">
                <div id="spinner"></div>
            </div>
            
        </>
    )
}