import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, useParams } from "react-router-dom";
import SliderContainer from '../Slider/SliderContainer';
import SelectDropdownBreadcrumbs from '../Select_Dropdown_Breadcrumbs/SelectDropdownBreadcrumbs';
import { useStateHooks } from '../../services/state_hook_service';
import { useData } from '../../services/data_service';
import Legend from "../Maps/Legend";
import Map from '../Maps/Map';
import Search from "../Maps/Search";
import ChartWrapper from "../Chart/ChartWrapper";
import Chevron from "../../images/chevron.png";
import {BsQuestion} from 'react-icons/bs';
import Interpretations from '../Interpretations/Interpretations';
import './Explorer.scss';

export default function Explorer(props) {
	const [postInitialRender, handlePostInitialRender] = useState(false);
	const [toggleMapPopup, setToggleMapPopup] = useState(false);
	const StateHookSvc = useStateHooks();
	const DataSvc = useData();

	useEffect(() => {
		handlePostInitialRender(true);
	}, []);

	useEffect(() => {
		DataSvc.fetchStateList();
	}, [StateHookSvc.selectedLandUse]);

	useEffect(() => {
	}, [StateHookSvc.stateList]);

	useEffect(() => {}, [StateHookSvc.references, StateHookSvc.expandCitations])

	function test(val){
		if(val > 4 && StateHookSvc.expandCitations == false){
			return {display: 'none'}
		}else{
			return null
		}
	}

	return (
		<>
		<div className="map-chart-outer-container" id="map-chart-outer-container">
			<SelectDropdownBreadcrumbs/>
			<div className="chart-container">
				<SliderContainer postInitialRender={postInitialRender}>
					{/* ----------------------- */}
					{/* Child Node 1 is the Map */}
					{/* ----------------------- */}
					<div className="map-container">
						<div className="map-widgets">
							{/* { StateHookSvc.mapView ? <Legend/> : null}
							{ StateHookSvc.mapView ? <Search/> : null} */}
							<span onClick={() => setToggleMapPopup(!toggleMapPopup)}><BsQuestion/></span>
						</div>
						<div className="map-wrapper">
							<p id="study-sites">Study Locations:</p>
							<div style={toggleMapPopup ? null : {display: 'none'}} className="map-tooltip-container">
								<p>The map below displays the sites that match the selected criteria.</p>
							</div>
							<Map
								setMapReady={props.setMapReady}
								setStudySitesReady={props.setStudySitesReady}
							/>
						</div>
					</div>
					{/* --------------------------------- */}
					{/* Child Node 2 is the Chart Wrapper */}
					{/* --------------------------------- */}
					<div className="filter-search-container">
						<div className="chart-view-container">
							<ChartWrapper></ChartWrapper>
						</div>
					</div>
					{/* -------------------------------------- */}
					{/* Child Node 3 is the Popup Data Display */}
					{/* -------------------------------------- */}
					<div id="customPopupContainer">
						
					</div>
				</SliderContainer>
			</div>
		</div>
		{window.location.pathname == '/explore/violin' ? <div>
			<Interpretations/>
		</div> : null}
		<div className="citations-outer-container">
			<div className="citation-button-container">
				<p id="citations-header">Citations</p>
				<button onClick={() => {StateHookSvc.setExpandCitations(!StateHookSvc.expandCitations)}}>{StateHookSvc.expandCitations == true ? 'Hide All Citations' : 'See All Citations'} <img src={Chevron} alt="chevron" style={StateHookSvc.expandCitations == false ? null: {transform: 'rotate(.5turn'}}/></button>
				<p></p>
			</div>
			<ul id="ref-list-body">
				{StateHookSvc.references !== null? StateHookSvc.references.map((i, index) => {
					return(
						<li className="reference" key={index} style={test(index)}><a href={i.reflink} target="_blank">{i.reference}</a></li>
					)
				}) : null}
			</ul>
		</div>
		{/* <div className="citation-button-container">
			<button onClick={() => {StateHookSvc.setExpandCitations(!StateHookSvc.expandCitations)}}>{StateHookSvc.expandCitations == true ? 'Hide All Citations' : 'See All Citations'} <img src={Chevron} alt="chevron" style={StateHookSvc.expandCitations == false ? null: {transform: 'rotate(.5turn'}}/></button>
		</div> */}
		
		</>
	)
};