import React from 'react';
import { useStateHooks } from '../../services/state_hook_service';
import "./Interpretations.scss";


export default function Interpretations(){
    const StateHookSvc = useStateHooks();

    return <>
        {StateHookSvc.showInterpretations ? 
            <>
                <p className="interpretations">Interpretations</p> 
                <p className="interpretations-text">Interpretations text will go here</p>
            </>
        : null}
    </>
}