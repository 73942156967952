import React, {ReactComponent} from 'react';
import Cropland_Icon from '../../images/nrcs-images/cropfield_transp1.png';
import Pasture_Icon from '../../images/nrcs-images/grazing_transp1.png';
import Forest_Icon from '../../images/nrcs-images/forest_transp1.png';
import Rangeland from '../../images/nrcs-images/rangeland_transp1.png';
import './LandUseIcon.scss'

export default function LandUseIcon(props){
    function handleImages(){
        if(props.landUseText == 'Cropland'){
            return <img src={Cropland_Icon} alt="cropland-icon" className="cropland-icon"/>
        } 
        else if (props.landUseText == 'Pastureland'){
            return <img src={Pasture_Icon} alt="pasture-icon" className="pasture-icon"/>
        } 
        else if (props.landUseText == 'Rangeland'){
            return <img src={Rangeland} alt="rangeland-icon" className="rangeland-icon"/>
        }
        else if (props.landUseText == 'Forestland'){
            return <img src={Forest_Icon} alt="forest-icon" className="forest-icon"/>
        }
    }
    return(
       <>
        {handleImages()}
       </>
    )
}