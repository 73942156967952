import SplitPane,
{
  Divider,
  SplitPaneBottom,
  SplitPaneLeft,
  SplitPaneRight,
  SplitPaneTop,
} 
from "./SplitPane";
import React, { useState } from "react";
import './SliderContainer.scss';

// This expects three child nodes passed into it.
// The Child Elements passed into this component are filled
// into the panels clockwise from the top left.
function SliderContainer(props) {
  const [currQuote, setCurrQuote] = useState(1);
  
  return (
    <div className="slider-container">
      <SplitPane className="split-pane-row" splitdirection="leftright">
        <SplitPaneLeft className="left-side">
          <SplitPane className="split-pane-col" splitdirection="updown">
            <SplitPaneTop postInitialRender={props.postInitialRender}>
              {props.children[0]}
            </SplitPaneTop>
            <Divider class={"separator-row"}/>
            <SplitPaneBottom>
              {props.children[2]}
            </SplitPaneBottom>
          </SplitPane>
        </SplitPaneLeft>
        <Divider class={"separator-col"}/>
        <SplitPaneRight className="right-side">
          {props.children[1]}
        </SplitPaneRight>
      </SplitPane>
    </div>
    );
  }
  
  export default SliderContainer;
  