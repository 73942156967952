import React, {useState, useEffect} from 'react';
import LandUseSelect from '../LandUseSelect/LandUseSelect';
import config from '../../config';
import './HomePage.scss'

export default function HomePage(props){
    const [postInitialRender, handlePostInitialRender] = useState(false);

    function displayVersionInfo(){
        // console.log('window.location.hostname',window.location.hostname)
        if(window.location.hostname == 'nrcs-core-dev.stone-env.net'){
            return null
        }else{
            return(
                <div className="versionInfo">
                    Version {config.VERSION_INFO} -- {config.COMMIT_INFO}
                </div>
            )
        }
    }

    return(
        <div className="HomePage-Container">
            <section className="welcome-container">
                <div id="welcome-title-container">
                    <h1>Welcome</h1>
                    <h3>Select a land use to begin exploring data</h3>
                </div>
                <LandUseSelect></LandUseSelect>
            </section>
            {displayVersionInfo()}
        </div>
    )
}