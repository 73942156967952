import React, {useState, useEffect} from 'react';
import Plot from 'react-plotly.js';
import Buffering from '../Buffering/Buffering';
import {useData} from '../../services/data_service';
import {useStateHooks} from '../../services/state_hook_service';
import { useHistory } from 'react-router';
import './PlotlyViolinTemplates.scss'; 

export default function PlotlyViolinTwoTemplate(props){
    const DataSvc = useData();
    const StateHookSvc = useStateHooks();
    const history = useHistory();
    const [initWidth, setInitWidth] = useState('100%');
    const [initHeight, setInitHeight] = useState('100%');

    useEffect(() => {
        DataSvc.fetchSecondViolin().then((res) => {
            StateHookSvc.setShowBuffer(false);
            StateHookSvc.setSecondViolinData(res);
        })
    }, [StateHookSvc.showNormData,
        StateHookSvc.includeBenchmark, 
        StateHookSvc.includeControl,
        StateHookSvc.selectedResourceComponent,
        StateHookSvc.selectedLandUse,
        StateHookSvc.violinPlotTitle
    ]);

    useEffect(() => {
        setInitWidth(StateHookSvc.chartDivWidth)
	}, [StateHookSvc.chartDivWidth])

    useEffect(() => {
        setInitHeight(StateHookSvc.chartDivHeight)
	}, [StateHookSvc.chartDivHeight])

    let config = {
        'displayModeBar': false,
        'modeBarButtonsToRemove': ['zoom2d', 'hoverCompareCartesian', 'hoverClosestCartesian', 'toggleSpikelines'],
        'responsive': true
    }

    let layout = {
        // title: `Percent Change for Treatments that Include <br> ${StateHookSvc.violinPlotTitle == 'Any'?StateHookSvc.violinPlotTitle + ` Conservation Practice`:StateHookSvc.violinPlotTitle} on ${StateHookSvc.selectedResourceComponent == 'Any'?StateHookSvc.selectedResourceComponent + ' Selected Resource Component':StateHookSvc.selectedResourceComponent}`,
        showlegend: false,
        xaxis: {
            zeroline: false,
            range: StateHookSvc.showNormData == true ? [-5, 5] :[-125,200],
            title: {
                text: `${StateHookSvc.showNormData == true ? 'Difference in Normalized Values' : 'Relative Difference (%)'}`,
                font: {
                    family: 'Roboto, sans-serif',
                    size: '20'
                },
                pad: 0
            },
            fixedrange: true
        },
        margin: {
            autoexpand: true,
            t: 0,
            pad: 0
        },
        autosize: true,
        height: initHeight,
        width: initWidth,
        yaxis: {
            fixedrange: true,
            automargin: true
        }
    }

    function pushToTable(val){
        for(let i = 0; i < StateHookSvc.unfilteredSecondViolinRes.length; i++){
            if(StateHookSvc.unfilteredSecondViolinRes[i].data_elements === val){
                StateHookSvc.setSelectedDataElem_id(StateHookSvc.unfilteredSecondViolinRes[i].data_element_id);
                break;
            }
        };
        StateHookSvc.setSelectedOutcome(val);
        DataSvc.filterMeasuredElementArray(val, history);
    }

    function renderSecondViolinPlot(){
        if(StateHookSvc.secondViolinData == null && StateHookSvc.showBuffer == false){
            return <h3>No Results Found. Please update your search and try again</h3>
        }else if(StateHookSvc.showBuffer == true){
            return <Buffering/>
        }else{
            return <Plot
                data={StateHookSvc.secondViolinData}
                layout={layout}
                config={config}
                onClick={(e) => pushToTable(e.points[0].y)}
            />
        }   
    };

    return(
        <>
            {renderSecondViolinPlot()}
        </>
    )
}