import React, {useState, useEffect} from 'react';
import { useStateHooks } from '../../services/state_hook_service';
import { useData } from '../../services/data_service';
import {BsArrowRight} from 'react-icons/bs';
import {useHistory} from 'react-router-dom';
import {ReactComponent as Arrow} from '../../images/Breadcrumbs-Icons/arrow.svg';
import {ReactComponent as GrayArrow} from '../../images/Breadcrumbs-Icons/grayArrow.svg';
import Select from 'react-select';
import {ReactComponent as Cross} from '../../images/Breadcrumbs-Icons/cross.svg';
import {BsQuestion} from 'react-icons/bs';
import { AriaOnFocus } from 'react-select';
import './SelectDropdownBreadcrumbs.scss';

export default function SelectDropdownBreadcrumbs(props){
    const StateHookSvc = useStateHooks();
    const DataSvc = useData();
    const [conservationPractice, setConservationPractice] = useState([]);
    const [treatment, setTreatment] = useState('select');
    const [outcome, setOutcome] = useState('false');
    const [showToolTip, setShowToolTip] = useState(false);
    const [toolTipText, setToolTipText] = useState('');
    const [tooltipLocation, setToolTipLocation] = useState('');
    const [landuseLink, setLandUseLink] = useState('');
    const [ariaFocusMessage, setAriaFocusMessage] = useState('');
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const history = useHistory();
    const dropdownFontSize = "14px";
    const dropdownFontFamily = '"Roboto"';
    const dropdownStyle = {
        menu: provided => ({...provided, zIndex: 9999, marginTop: 0, fontSize: dropdownFontSize, fontFamily: dropdownFontFamily}),
        container: provided => ({
            ...provided, 
            zIndex: 9999, 
            marginTop: 0, 
            maxHeight: '32px', 
            minHeight: '32px', 
            fontSize: dropdownFontSize, 
            fontFamily: dropdownFontFamily
        }),
        indicatorsContainer: provided => ({...provided, zIndex: 9999, marginTop: 0, maxHeight: '32px', minHeight: '32px', padding: 0}),
        control: provided => ({
            ...provided, 
            zIndex: 9999, 
            marginTop: 0, 
            maxHeight: '32px', 
            minHeight: '32px', 
            fontSize: dropdownFontSize, 
            fontFamily: dropdownFontFamily,
            "@media only screen and (min-width: 2000px)": {
                ...provided,
                maxHeight: '60px',
                minHeight: '60px',
                minWidth: '275px',
                maxWidth: '275px'

            }}),
        singleValue: provided => ({
            ...provided, 
            zIndex: 9999, 
            marginTop: 0, 
            fontSize: dropdownFontSize, 
            fontFamily: dropdownFontFamily,
            "@media only screen and (min-width: 2000px)": {
                ...provided,
                fontSize: '25px'
            }}),
        valueContainer: provided => ({...provided, zIndex: 9999, marginTop: 0, padding: '0 10px', fontSize: dropdownFontSize, fontFamily: dropdownFontFamily}),
        menuList: provided => ({
            ...provided, 
            zIndex: 9999, 
            maxHeight: undefined,  
            fontSize: dropdownFontSize, 
            fontFamily: dropdownFontFamily,
            "@media only screen and (min-width: 2000px)": {
                ...provided,
                maxHeight: '350px',
                minHeight: '50px',
                fontSize: '25px',
            }
        }),
        menu: provided => ({
            ...provided,
            zIndex: 9999, 
            marginTop: 0,
            "@media only screen and (min-width: 2000px)": {
                ...provided,
                marginTop: '20px',
                backgroundColor: 'white'
                // maxHeight: '120px',
                // minHeight: '20px'
            }
        }),
        placeholder: provided => ({
            ...provided, zIndex: 9999, 
            maxHeight: undefined,  
            fontSize: dropdownFontSize, 
            fontFamily: dropdownFontFamily, 
            fontStyle: "italic",
            "@media only screen and (min-width: 2000px)": {
                ...provided,
                fontSize: '25px'
            }
        }),
        // onFocus: ({focused, isDisabled}) => {
        //     const message = `You are currently focused on option ${focused.label}${
        //         isDisabled ? ', disabled' : ''}
        //     }`;
        //     setAriaFocusMessage(message);
        //     return message;
        // }
    }

    const onMenuOpen = () => setIsMenuOpen(true);
    const onMenuClose = () => setIsMenuOpen(false);

    useEffect(() => {
        if(StateHookSvc.selectedLandUse.landuse == 'Rangeland'){
            setLandUseLink('https://www.nrcs.usda.gov/conservation-basics/natural-resource-concerns/land/range-pasture/range-resources');
        } else if(StateHookSvc.selectedLandUse.landuse == 'Pastureland'){
            setLandUseLink('https://www.nrcs.usda.gov/resources/data-and-reports/pasture-resources');
        }else if(StateHookSvc.selectedLandUse.landuse == 'Forestland'){
            setLandUseLink('https://www.nrcs.usda.gov/conservation-basics/natural-resource-concerns/land/forests');
        }else if(StateHookSvc.selectedLandUse.landuse == 'Cropland'){
            setLandUseLink('https://www.nrcs.usda.gov/conservation-basics/natural-resource-concerns/land/cropland');
        }
    }, [StateHookSvc.selectedLandUse]);

    useEffect(() => {
        DataSvc.getCounts();
        DataSvc.getCitations();
        if(window.location.pathname === '/explore'){
            DataSvc.fetchListTableData().then(() => {
                StateHookSvc.setShowBuffer(false);
            });
        };
    }, [
        StateHookSvc.landUseArray,
        StateHookSvc.dataTableLabels,
        StateHookSvc.conservationDropdown,
        StateHookSvc.totalWeightArr,
        StateHookSvc.selectedLandUse,
        StateHookSvc.selectedPracticeCode,
        StateHookSvc.selectedResourceComponent_id,
        StateHookSvc.selectedDataElem_id
    ]);

    function getTotalObs(){
        let count = 0;
        for(let i = 0; i < StateHookSvc.totalWeightArr.length; i++){
            count = count + StateHookSvc.totalWeightArr[i];
        }
        return StateHookSvc.setTotalObsCount(count);
    }

    function pushToViolin(values){
        if(showToolTip && tooltipLocation == 'conservation'){
            setShowToolTip(false);
            setToolTipLocation('');
            setToolTipText('');
        }
         if (StateHookSvc.selectedPracticeCode && values.label == 'Any'){
            return(
                StateHookSvc.setSelectedPracticeCode(values.value.practcode),
                StateHookSvc.setViolinPlotTitle(values.value.practname)
            );
        } else if(window.location.pathname == '/explore/table-results'){
            return(
                StateHookSvc.setSelectedPracticeCode(values.value.practcode),
                StateHookSvc.setViolinPlotTitle(values.value.practname)
            );
        }
        else{
            // console.log('values', values);
            let practcode = values.value.practcode;
            let practname = values.value.practname;
            StateHookSvc.setSelectedResourceComponent_id(null);
            DataSvc.handleArcClick(practname, history, practcode, 'breadcrumb');
        }
        
    }

    function renderConservationPracticesDropdown(){
        let dropdownItems = [];
        if(!StateHookSvc.dataTableLabels){
            return;
        }
        else{
            // sorting function for dropdown
            StateHookSvc.dataTableLabels.sort(function(a,b){
                return a.practname > b.practname ? 1 : -1;
            });
            for(let i = 0; i < StateHookSvc.dataTableLabels.length; i++){
                if(StateHookSvc.dataTableLabels[i].practname === 'Any'){
                    dropdownItems.unshift({
                        'value': StateHookSvc.dataTableLabels[i],
                        'label': StateHookSvc.dataTableLabels[i].practname
                    });
                }
                else{
                    dropdownItems.push({
                        'value': StateHookSvc.dataTableLabels[i],
                        'label': StateHookSvc.dataTableLabels[i].practname
                    });
                }
            }
        }
        return(
            <div className="dropdown" id="chord">
                 <div style={showToolTip && tooltipLocation == 'conservation'? null : {display: 'none'}} className="popup-container">
                    {toolTipText}
                </div>
                <Select
                    // ariaLiveMessages={
                    //     dropdownStyle.onFocus
                    // }
                    // ariaLiveMessages='conservation-test'
                    // aria-labelledby="aria-label"
                    // onMenuOpen={onMenuOpen}
                    // onMenuClose={onMenuClose}
                    options={dropdownItems}
                    placeholder="Select"
                    onChange={(e) => pushToViolin(e)}
                    styles={dropdownStyle}
                    // menuIsOpen={true}
                    hideSelectedOptions={true}
                    isClearable={true}
                    menuPlacement="auto"
                    {...props}
                    value = {
                        StateHookSvc.chordArcClick? {label: 'Multiple'} :
                        dropdownItems.filter(option => option.label === StateHookSvc.violinPlotTitle)
                    }
                    components={{ ClearIndicator: () =>  <span className="close"><Cross onClick={(e) => removeConservationPractice(e)}/></span>}}
                    control= {base => ({
                        ...base,
                        fontFamily: 'Roboto',
                      })
                    }
                />
            </div>
        )
    }

    function renderResourceConcernDropdown(){
        let violinDropdown = [];
        if(StateHookSvc.uniqViolinResources == null){
            return;
        }
        else{
            // alphabetical sorting function
           StateHookSvc.uniqViolinResources.sort(function(a,b){
               return a > b ? 1 : -1;
           });
            for(let i = 0; i < StateHookSvc.uniqViolinResources.length; i++){
                if(StateHookSvc.uniqViolinResources[i] === 'Any'){
                    violinDropdown.unshift({
                        'value': StateHookSvc.uniqViolinResources[i],
                        'label': StateHookSvc.uniqViolinResources[i]
                    });
                }
                else{
                    violinDropdown.push({
                        'value': StateHookSvc.uniqViolinResources[i],
                        'label': StateHookSvc.uniqViolinResources[i]
                    });
                }
            };
            return(
                <div className="dropdown" id="violin-one">
                    <div style={showToolTip && tooltipLocation == 'res_conn'? null : {display: 'none'}} className="popup-container">
                        {toolTipText}
                    </div>
                    <Select
                        // aria-labelledby="aria-label"
                        // ariaLiveMessages={
                        //     dropdownStyle.onFocus
                        // }
                        // ariaLiveMessages='conservation-test'
                        // onMenuOpen={onMenuOpen}
                        // onMenuClose={onMenuClose}
                        options={violinDropdown}
                        placeholder="Select"
                        onChange={(e) => routeToSecondViolin(e)}
                        hideSelectedOptions={true}
                        isClearable={true}
                        styles={dropdownStyle}
                        {...props}
                        value = {
                            violinDropdown.filter(option => option.label === StateHookSvc.selectedResourceComponent)
                        }
                        isDisabled={!StateHookSvc.violinPlotTitle}
                        components={{ ClearIndicator: () =>  <span className="close"><Cross onClick={(e) => removeResourceConcern(e)}/></span>}}
                    />
                </div>
            )
        }
    }

    function routeToSecondViolin(value){
        if(StateHookSvc.violinPlotTitle && value.label == 'Any'){
            StateHookSvc.setSelectedResourceComponent(value.value);
            StateHookSvc.setSelectedResourceComponent_id(0);
        }
        else if(window.location.pathname == '/explore/table-results'){
            for(let i = 0; i < StateHookSvc.unfilteredFirstViolinRes.length; i++){
                if(StateHookSvc.unfilteredFirstViolinRes[i].resource_component == value.value){
                    StateHookSvc.setSelectedResourceComponent_id(StateHookSvc.unfilteredFirstViolinRes[i].rescomp_id);
                    break;
                }
            }
            StateHookSvc.setSelectedResourceComponent(value.value);
        }
        else{
            for(let i = 0; i < StateHookSvc.unfilteredFirstViolinRes.length; i++){
                if(StateHookSvc.unfilteredFirstViolinRes[i].resource_component == value.value){
                    StateHookSvc.setSelectedResourceComponent_id(StateHookSvc.unfilteredFirstViolinRes[i].rescomp_id);
                    break;
                }
            }
            DataSvc.pushToSecondViolin(value.value);
        }
    }

    function renderMeasuredElementDropdown(){
        let secondViolinDropdown = [];
        // alphabetical sorting function
        StateHookSvc.uniqSecondViolinResources.sort(function(a,b){
            return a > b ? 1 : -1;
        });
        for(let i = 0; i < StateHookSvc.uniqSecondViolinResources.length; i++){
            if(StateHookSvc.uniqSecondViolinResources[i] === 'Any'){
                secondViolinDropdown.unshift({
                    'label': StateHookSvc.uniqSecondViolinResources[i],
                    'value': StateHookSvc.uniqSecondViolinResources[i]
                });
            }
            else{
                secondViolinDropdown.push({
                    'label': StateHookSvc.uniqSecondViolinResources[i],
                    'value': StateHookSvc.uniqSecondViolinResources[i]
                });
            }
            
        };
        return(
            <div className="dropdown" id="violin-two">
                <div style={showToolTip && tooltipLocation == 'mea_elem'? null : {display: 'none'}} className="popup-container">
                    {toolTipText}
                </div>
                <Select
                    // aria-labelledby="aria-label"
                    // ariaLiveMessages={
                    //     dropdownStyle.onFocus
                    // }
                    // ariaLiveMessages='conservation-test'
                    // onMenuOpen={onMenuOpen}
                    // onMenuClose={onMenuClose}
                    placeholder="Select"
                    options={secondViolinDropdown}
                    hideSelectedOptions={true}
                    isClearable={true}
                    onChange={(e) => selectMeasuredElement(e.label)}
                    styles={dropdownStyle}
                    {...props}
                    value = {
                        secondViolinDropdown.filter(option => option.label === StateHookSvc.selectedOutcome)
                    }
                    isDisabled={!StateHookSvc.selectedResourceComponent}
                    components={{ ClearIndicator: () =>  <span className="close"><Cross onClick={(e) => removeMeasuredElement(e.label)}/></span>}}
                />
            </div>
        )
    }

    function renderLandUseDropdown(){
        let landUseArr = [];
        // alphabetical sorting function
        StateHookSvc.landUseArray.sort(function(a,b){
            return a.landuse > b.landuse ? 1 : -1;
        });
        for(let i = 0; i < StateHookSvc.landUseArray.length; i++){
            if(StateHookSvc.landUseArray[i].landuse === 'Any'){
                landUseArr.unshift({
                    'value': StateHookSvc.landUseArray[i],
                    'label': StateHookSvc.landUseArray[i].landuse
                });
            }
            else{
                landUseArr.push({
                    'value': StateHookSvc.landUseArray[i],
                    'label': StateHookSvc.landUseArray[i].landuse
                });
            }
        }
        return(
            <div className="dropdown" id="landuse">
                <div style={showToolTip && tooltipLocation == 'land'? null : {display: 'none'}} className="popup-container">
                    {toolTipText}
                </div>
                <label style={{display: 'none'}}>Land Use Dropdown</label>
                <Select
                    // ariaLiveMessages={
                    //     dropdownStyle.onFocus
                    // }
                    // ariaLiveMessages='conservation-test'
                    // onMenuOpen={onMenuOpen}
                    // onMenuClose={onMenuClose}
                    // aria-labelledby="aria-label"
                    placeholder="Select"
                    styles={dropdownStyle}
                    options={landUseArr}
                    onChange={(e) => changeSelectLandUse(e)}
                    hideSelectedOptions={true}
                    isClearable={true}
                    {...props}
                    value = {
                        landUseArr.filter(option => option.label === StateHookSvc.selectedLandUse.landuse)
                    }
                    components={{ ClearIndicator: () =>  <span className="close"><Cross onClick={(e) => removeLandUse(e)}/></span>}}
                />
            </div>
        )   
    };

    function changeSelectLandUse(val){
        // console.log('val', val)
        if(val == null){
            StateHookSvc.setViolinPlotTitle('');
            StateHookSvc.setSelectedResourceComponent('');
            StateHookSvc.setSelectedOutcome(val);
            StateHookSvc.setSelectedPracticeCode(null);
            StateHookSvc.setMapClick(false);
            return history.push('/home');
        }
        else if(val.label == 'Any'){
            return StateHookSvc.setSelectedLandUse(val.value);
        } else if (window.location.pathname == '/explore/table-results'){
            StateHookSvc.setSelectedLandUse(val.value);
        }
        else{
            StateHookSvc.setSelectedLandUse(val.value);
            StateHookSvc.setViolinPlotTitle('');
            StateHookSvc.setSelectedResourceComponent('');
            StateHookSvc.setDataTableLabels([]);
            StateHookSvc.setSelectedPracticeCode(null);
            StateHookSvc.setSelectedResourceComponent_id(null);
            StateHookSvc.setMapClick(false);
            DataSvc.fetchChordChartData(history).then(res => {
                StateHookSvc.setChordChartOptions(res)
            });
            history.push('/explore');
        }
    };

    function removeLandUse(val){
        StateHookSvc.setViolinPlotTitle('');
        StateHookSvc.setSelectedResourceComponent('');
        StateHookSvc.setSelectedOutcome(val);
        StateHookSvc.setSelectedPracticeCode(null);
        StateHookSvc.setIncludeBenchmark(false);
        StateHookSvc.setIncludeControl(true);
        StateHookSvc.setNormData(false);
        StateHookSvc.setRenderFinalTable(false);
        StateHookSvc.setSelectedLandUse(null);
        StateHookSvc.setSelectedPracticeCode(null);
        StateHookSvc.setSelectedResourceComponent_id(null);
        StateHookSvc.setSelectedDataElem_id(null);
        StateHookSvc.setChordArcClick(false);
        StateHookSvc.setConPracTooltip(null);
        StateHookSvc.setMapClick(false);
        setShowToolTip(false);
        setToolTipLocation('');
        setToolTipText('');
        return history.push('/home');
    };
    function removeConservationPractice(values){
        StateHookSvc.setViolinPlotTitle('');
        StateHookSvc.setSelectedResourceComponent('');
        StateHookSvc.setSelectedOutcome(values);
        StateHookSvc.setSelectedPracticeCode(null);
        StateHookSvc.setIncludeBenchmark(false);
        StateHookSvc.setIncludeControl(true);
        StateHookSvc.setNormData(false);
        StateHookSvc.setRenderFinalTable(false);
        StateHookSvc.setSelectedPracticeCode(null);
        StateHookSvc.setSelectedResourceComponent_id(null);
        StateHookSvc.setSelectedDataElem_id(null);
        StateHookSvc.setChordArcClick(false);
        StateHookSvc.setConPracTooltip(null);
        if(showToolTip && tooltipLocation == 'conservation'){
            setShowToolTip(false);
            setToolTipLocation('');
            setToolTipText('');
        }
        history.push('/explore');
        return;
    }
    function removeResourceConcern(){
        StateHookSvc.setSelectedOutcome('');
        StateHookSvc.setSelectedResourceComponent('');
        StateHookSvc.setIncludeBenchmark(false);
        StateHookSvc.setIncludeControl(true);
        StateHookSvc.setNormData(false);
        StateHookSvc.setRenderFinalTable(false);
        StateHookSvc.setSelectedResourceComponent_id(null);
        StateHookSvc.setSelectedDataElem_id(null);
        if(window.location.pathname == '/explore/table-results'){
            history.push('/explore/violin');
        }
        return;
    };

    function removeMeasuredElement(val){
        StateHookSvc.setSelectedOutcome(val);
        StateHookSvc.setRenderFinalTable(false);
        StateHookSvc.setIncludeControl(true);
        StateHookSvc.setIncludeBenchmark(false);
        StateHookSvc.setSelectedDataElem_id(null);
        if(window.location.pathname == '/explore/table-results'){
            history.push('/explore/violin');
        }
    }

    function selectMeasuredElement(val){
        if(window.location.pathname !== '/explore/table-results'){
            StateHookSvc.setRenderFinalTable(true);
        }
        for(let i = 0; i < StateHookSvc.unfilteredSecondViolinRes.length; i++){
            if(StateHookSvc.unfilteredSecondViolinRes[i].data_elements === val){
                StateHookSvc.setSelectedDataElem_id(StateHookSvc.unfilteredSecondViolinRes[i].data_element_id);
                break;
            }
        }
        StateHookSvc.setSelectedOutcome(val);
    }

    function handleToolTipDisplay(str){
        //we may need to re-work this logic to meet what we're going for
        
        if(showToolTip == false){
            setShowToolTip(true);
            if(str == 'land'){
                setToolTipLocation(str);
                return setToolTipText(<p>NRCS definition of the <a href={landuseLink} target="_blank">selected land use</a></p>);
            }
            else if(str == 'conservation'){
                console.log('1');
                setToolTipLocation(str);
                if(StateHookSvc.conPracTooltip){
                    console.log('2');
                    return setToolTipText(
                        <>
                            <p>{StateHookSvc.conPracTooltip.practname} : {StateHookSvc.conPracTooltip.pract_tooltip} Link to NRCS <a href="https://www.nrcs.usda.gov/wps/portal/nrcs/detailfull/national/technical/cp/ncps/?cid=nrcs143_026849" target="_blank">Conservation Practice Standards</a></p>
                        </>

                    );
                }
                console.log('3')
                return setToolTipText(<p>Link to NRCS <a href="https://www.nrcs.usda.gov/resources/guides-and-instructions/conservation-practice-standards" target="_blank">Conservation Practice Standards</a></p>);
            }
            else if(str == 'res_conn'){
                setToolTipLocation(str);
                if(StateHookSvc.resConTooltip){
                    return setToolTipText(
                        <>
                            <p>{StateHookSvc.resConTooltip.resource_component} : {StateHookSvc.resConTooltip.tooltip} Link to NRCS <a href="https://www.nrcs.usda.gov/conservation-basics/natural-resource-concerns" target="_blank">Conservation Practice Standards</a></p>
                        </>
                    )
                }
                return setToolTipText(<p>A resource concern is an expected degradation of the soil, water, air, plant, or animal resource base to an extent that the sustainability or intended use of the resource is impaired. <span><a href="https://www.nrcs.usda.gov/conservation-basics/natural-resource-concerns" target="_blank">Link to NRCS Conservation Practice Standards</a></span></p>)
            }
            else if(str == 'mea_elem'){
                setToolTipLocation(str);
                return setToolTipText(<p>Tooltip text for this section will go here</p>)
            }
        }
        else if(showToolTip == true && tooltipLocation !== str){
            console.log('firing', )
            if(str == 'land'){
                setToolTipLocation(str);
                return setToolTipText(<p>NRCS definition of the <a href={landuseLink} target="_blank">selected land use</a></p>);
            }
            else if(str == 'conservation'){
                setToolTipLocation(str);
                console.log('1')
                return setToolTipText(<p>Link to NRCS <a href="https://www.nrcs.usda.gov/resources/guides-and-instructions/conservation-practice-standards" target="_blank">Conservation Practice Standards</a></p>);
            }
            else if(str == 'res_conn'){
                setToolTipLocation(str);
                return setToolTipText(<p>A resource concern is an expected degradation of the soil, water, air, plant, or animal resource base to an extent that the sustainability or intended use of the resource is impaired. <span><a href="https://www.nrcs.usda.gov/conservation-basics/natural-resource-concerns" target="_blank">Link to NRCS Conservation Practice Standards</a></span></p>)
            }
            else if(str == 'mea_elem'){
                setToolTipLocation(str);
                return setToolTipText(<p>Tooltip text for this section will go here</p>)
            }
        }
        else{
            setShowToolTip(false);
            setToolTipText('');
            setToolTipLocation('');
        }

    }

    return(
        <> 
        {/* inline css to test device display consistency */}
            <span id="select-header" style={{display:'none'}}>Select</span>
            <form id="dropdown-breadcrumbs" style={showToolTip ? {height: '150px'} : {height: '65px'}}>
                <section id="dropdown-container">
                    <div className="land-use-dropdown">
                        {/* {backgroundColor: '#BFD1E3', padding: '2px'} */}
                        <label for="Land_Use_Types">Land Use<span style={showToolTip && tooltipLocation == 'land'? {backgroundColor: '#BFD1E3', padding: '2px'} : null} className="breadcrumb-dropdown-icon" title="NRCS definition of the selected land use" onClick={() => handleToolTipDisplay('land')} id="bc_landuse"><BsQuestion/></span></label>
                        {renderLandUseDropdown()}
                        {/* <div style={showToolTip ? null : {display: 'none'}}>
                            <p>Good Morning Dave</p>
                        </div> */}
                    </div>
                    <span className="arrow"><Arrow/></span>
                    <div className="land-use-dropdown">
                        <label for="Conservation_Practice">Conservation Practice<span style={showToolTip && tooltipLocation == 'conservation'? {backgroundColor: '#BFD1E3', padding: '2px'} : null} className="breadcrumb-dropdown-icon" title="Link to NRCS Conservation Practice Standards" onClick={() => handleToolTipDisplay('conservation')} id="bc_con_pract"><BsQuestion/></span></label>
                        {renderConservationPracticesDropdown()}
                    </div>
                    <span className="arrow">{StateHookSvc.violinPlotTitle ? <Arrow/> : <GrayArrow/>}</span>
                    <div className="land-use-dropdown">
                        <label for="Treatment" style={StateHookSvc.violinPlotTitle ? null : {color: 'gray'}}>Resource Concerns<span style={showToolTip && tooltipLocation == 'res_conn'? {backgroundColor: '#BFD1E3', padding: '2px'} : null} className="breadcrumb-dropdown-icon" onClick={() => StateHookSvc.violinPlotTitle ? handleToolTipDisplay('res_conn'): null} id={StateHookSvc.violinPlotTitle ? 'bc_violin_1_on' : null}><BsQuestion/></span></label>
                        {renderResourceConcernDropdown()}
                    </div>
                    <span className="arrow">{StateHookSvc.selectedResourceComponent ? <Arrow/> : <GrayArrow/>}</span>
                    <div className="land-use-dropdown">
                        <label for="Measured Element" style={StateHookSvc.selectedResourceComponent? null : {color: 'gray'}}>Measured Element<span style={showToolTip && tooltipLocation == 'mea_elem'? {backgroundColor: '#BFD1E3', padding: '2px'} : null} className="breadcrumb-dropdown-icon" onClick={() => StateHookSvc.selectedResourceComponent ? handleToolTipDisplay('mea_elem') : null} id={StateHookSvc.selectedResourceComponent ? 'bc_violin_2_on' : null}><BsQuestion/></span></label>
                        {renderMeasuredElementDropdown()}
                    </div>
                </section>
                <div className="search-bubble">
                    <p>There {StateHookSvc.totalStudyCount == 1 ? 'is' : 'are'} <span className="number">{StateHookSvc.totalStudyCount}</span> {StateHookSvc.totalStudyCount == 1 ? 'study' : 'studies'}, <span className="number">{StateHookSvc.studySiteCount}</span> {StateHookSvc.studySiteCount == 1 ? 'study location' : 'study locations'}, and <span className="number">{StateHookSvc.totalObsCount}</span> {StateHookSvc.totalObsCount == 1 ? 'observation' :'observations'} associated with your selection.</p>
                </div>
            </form>
        </>
    )
}