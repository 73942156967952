import React, {useState, createContext, useContext} from 'react';
import { useHistory } from 'react-router-dom';
import {useStateHooks} from './state_hook_service';
import {BsQuestion} from 'react-icons/bs';
import config from '../config';
import * as d3 from 'd3-format';

const DataContext = createContext(null);

export const DataProvider = ({children, ...services}) => {
    const [dataTableLabels, setDataTableLabels] = useState([]);
    const [selectedLandUse, setSelectedLandUse] = useState(null);
    const [selectedState, setSelectedState] = useState("");
    const [selPracCode, setSelPracCode] = useState(null);
    const [uniqueValState, handleUniqueValState] = useState([]);
    const [landUseArray, setLandUseArray] = useState([]);
    const [showNormalizedData, setShowNormalizedData] = useState(false);
    const history = useHistory(); 
    const StateHookSvc = useStateHooks();
    const chartColors = [
        // 'rgb(129, 228, 215)',
        // 'rgb(51, 214, 221)',
        // 'rgb(0, 198, 232)',
        // 'rgb(0, 180, 244)',
        // 'rgb(0, 160, 254)',
        // 'rgb(0, 137, 255)',
        // 'rgb(0, 108, 245)',
        // 'rgb(102, 70, 220)',
        // 'rgb(102, 70, 220)',
        // 'rgb(0, 90, 219)',
        // 'rgb(0, 96, 200)',
        // 'rgb(0, 94, 167)',
        // 'rgb(0, 88, 130)',
        // 'rgb(0, 81, 93)',
        // 'rgb(0, 72, 64)',
        // 'rgb(0, 62, 44)'
        // 'rgb(32, 85, 139)',
        // 'rgb(0, 88, 140)',
        // 'rgb(0, 91, 139)',
        // 'rgb(0, 93, 137)',
        // 'rgb(0, 95, 133)',
        // 'rgb(0, 97, 128)',
        // 'rgb(0, 99, 122)',
        // 'rgb(0, 100, 114)',
        // 'rgb(0, 101, 106)',
        // 'rgb(0, 101, 97)',
        // 'rgb(0, 101, 87)',
        // 'rgb(0, 101, 77)',
        // 'rgb(0, 101, 67)',
        // 'rgb(7, 101, 58)',
        // 'rgb(35, 100, 48)'
        // https://www.toptal.com/designers/colourcode/#/freebuild/20558b-236430-2b4c81-277676-256f4f-4c538c-264271-3e6522-2a7e56-25636e-2f858c-1d5655-329697-3c2163-5e7727-379130-4f339b
        '#20558b',
        '#236430',
        '#2b4c81',
        '#277676',
        '#256f4f',
        '#4c538c',
        '#264271',
        '#3e6522',
        '#2a7e56',
        '#25636e',
        '#2f858c',
        '#1d5655',
        '#329697',
        '#3c2163',
        '#5e7727',
        '#379130',
        '#4f339b'
        // 'rgb(21, 60, 106)', 
        // 'rgb(32, 84, 147)', 
        // 'rgb(62, 126, 202)', 
        // 'rgb(120, 165, 219)', 
        // 'rgb(193, 218, 246)', 
        // 'rgb(73, 117, 120)', 
        // 'rgb(107, 174, 178)', 
        // 'rgb(129, 202, 207)', 
        // 'rgb(168, 240, 245)', 
        // 'rgb(230, 252, 254)',
        // 'rgb(230, 128, 128)'
    ]
    const fetchViolinData = () => {
        // console.log('landuseid', StateHookSvc.selectedLandUse.landuse_id);
        // console.log('practicecode', StateHookSvc.selectedPracticeCode);
        StateHookSvc.setShowBuffer(true);
        return fetch(
            `${config.API_URL}/get_percentchange_resources_practcode2`,
            {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    'landuseid': StateHookSvc.selectedLandUse.landuse_id, 
                    'practicecode': StateHookSvc.selectedPracticeCode
                })
            }
        )
        .then( res => {
            if(res.ok){
                return res.json()
            }
        })
        .then(resJson => {
            // first check to see if it's null
            // console.log('first violin resJson', resJson)
            let responseData = resJson[0].get_percentchange_resources_practcode2;
            if(responseData == null){
                console.log('responseData null')
                return null
            }
            StateHookSvc.setUnfilteredFirstViolinRes(responseData);
            getIndividualResourceConerns(responseData, 1)
            if(StateHookSvc.includeBenchmark == true && StateHookSvc.includeControl == false){
                // when only Benchmark is true, exclude include controls
                responseData = responseData.filter(i => i.studytype !== 'treatcontrol');
                if(!responseData.length){
                    return null
                }
            } else if (StateHookSvc.includeControl == true && StateHookSvc.includeBenchmark == false){
                // when includeControl is true, exclude benchmark
                responseData = responseData.filter(i => i.studytype == 'treatcontrol');
                if(!responseData.length){
                    return null
                }
            }
            responseData = responseData.filter(i => i.resource_component !== 'Any');
            responseData.sort(function(a,b){
                return a.resource_component < b.resource_component ? 1 : -1;
            });
            if(!responseData.length){
                console.log('post process empty')
                return null
            }else{
                let popupValues = []
                for(let i = 0; i < responseData.length; i++){
                    let row = responseData[i];
                    popupValues.push([row["meanvalue"], row["maxvalue"], row["minvalue"]]);
                    // max.push(row["maxvalue"]);
                    // min.push(row["minvalue"]);
                }
                let violinObject = [{
                    type: 'violin',
                    name: 'Relative Difference',
                    x: unpack(responseData, 'resource_component'),
                    x: StateHookSvc.showNormData == true? unpack(responseData, 'pct_avgannchange_normal'): unpack(responseData, 'pct_avgannchange'),
                    points: 'all',
                    spanmode: 'hard',
                    scalemode: 'width',
                    width: 0.5, 
                    hoveron: "points",
                    marker: {
                        line: {
                            width: 2,
                            color: "#bebada"
                        },
                        symbol: "circle-open",
                        color: '#96B9D0',
                        size: 2,
                        opacity: 1
                    },
                    line: {
                        color: "rgb(21, 60, 106)"
                    },
                    jitter: 0.8,
                    pointpos: -1,
                    size: 1,
                    // color: '#20558A',
                    meanline: {
                        visible: true,
                        width: 6,
                        color: 'rgb(255, 255, 0)'  
                    },
                    side: 'positive',
                    fillcolor: "#20558A",
                    // opacity: 0.6,
                    box: {
                        visible: false,  
                        line:  {
                        color: "black"
                        },
                        width: 1
                    },
                    transforms: [{
                        type: 'groupby',
                        groups: unpack(responseData, 'resource_component'),
                    }],
                    // troubleshooting tooltip here
                    // text: "test",
                    // marker:{
                    //     size: mean,
                    //     // sizeref: 4000,
                    //     sizemode: "area"
                    // },
                    // marker: unpackTooltip(responseData, 'meanvalue'),
                    // meandata: mean,
                    // maxdata: max,
                    customdata: popupValues,
                    hovertemplate: "Min: %{customdata[2]} <br> Max: %{customdata[1]} <br> Mean: %{customdata[0]}<extra></extra>",
                    xhoverformat: d3.format(".1f"),
                }];
                return violinObject
            }
        })
        .catch(e => console.warn('e', e))
    };

    function formatViolinObject(){
        if(StateHookSvc.unfilteredFirstViolinRes.length){
            console.log('empty unfilteredViolinRes');
        }
        let violinObject = [{
            type: 'violin',
            name: 'Relative Difference',
            x: unpack(StateHookSvc.unfilteredFirstViolinRes, 'resource_component'),
            x: StateHookSvc.showNormData == true? unpack(StateHookSvc.unfilteredFirstViolinRes, 'pct_avgannchange_normal'): unpack(StateHookSvc.unfilteredFirstViolinRes, 'pct_avgannchange'),
            points: 'all',
            spanmode: 'hard',
            scalemode: 'width',
            width: 0.5, 
            
            // marker: {
            //     line: {
            //         width: 2,
            //         color: "#bebada"
            //     },
            //     symbol: "circle-open",
            //     color: '#96B9D0',
            //     size: 2,
            //     opacity: 1
            // },
            line: {
                color: "rgb(21, 60, 106)"
            },
            jitter: 0.8,
            pointpos: -1,
            size: 1,
            color: '#20558A',
            meanline: {
                visible: true,
                width: 4,
                color: 'black'  
            },
            side: 'positive',
            fillcolor: "#20558A",
            // opacity: 0.6,
            box: {
                visible: false,  
                line:  {
                color: "black"
                },
                width: 1
            },
            transforms: [{
                type: 'groupby',
                groups: unpack(StateHookSvc.unfilteredFirstViolinRes, 'resource_component'),
            }]
        }];
        return violinObject
    }

    function pushToSecondViolin(e){
        // console.log('pushToSecondViolin e', e, StateHookSvc.firstViolinTooltips);
        StateHookSvc.setSelectedResourceComponent(e);
        StateHookSvc.setNormData(false);
        StateHookSvc.setIncludeControl(true);
        StateHookSvc.setIncludeBenchmark(false);
    };

    const fetchChordChartData = (history) => {
        StateHookSvc.setShowBuffer(true);
        StateHookSvc.setUniqViolinResources([]);
        return fetch(
            `${config.API_URL}/get_chordchart`,
            {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    'landuseid': StateHookSvc.selectedLandUse.landuse_id,
                    'fips': StateHookSvc.selectedState.value
                })
            }
        )
        .then(res => {
            if(res.ok){
                return res.json();
            }
        })
        .then(resJson => {
            let formattedData = resJson[0].get_chordchart;
            // console.log('database response chord chart', resJson);
            if(!formattedData.length){
                StateHookSvc.setShowBuffer(false);
                return null;
            }
            let formattedArcLabels_results = [];
            let dataTable_results = [];
            formattedData = formattedData.filter(val => val.from !== 'Any' && val.to !== 'Any');
            formatArcLabels(formattedData, formattedArcLabels_results, dataTable_results);
            let title = "";
            title = `Research Treatments Related to ${StateHookSvc.selectedLandUse.landuse} Conservation Practices`;
            if (StateHookSvc.selectedState ) title += ` in ${StateHookSvc.selectedState.label}`;
            StateHookSvc.setShowBuffer(false);
            let nodePadding = 10;
            return {
                title: { 
                    // adjust the style for the title object here
                    text: title, 
                    style: {'font-size': '20px', 'font-weight': '700', 'font-family':'Roboto, sans-serif'},
                    y: 25
                },
                // accessibility: {
                //     point: {
                //         valueDescriptionFormat: '{index}. From {point.from} to {point.to}: {point.weight} studies.'
                //     }
                // },
                colors: chartColors,
                // tooltip logic
                tooltip: {
                    formatter: function(){
                        let s;
                        if (this.series.name==='Series 2' || this.point.shapeType == 'arc') {
                            let total;
                            // console.log('point', this.point);
                            if (this.point.options.isNode){
                                //if node then its an arc
                                total = this.point.sum
                                // console.log('sum total', total)
                            }else{
                                total = this.point.y
                            }
                            let target = StateHookSvc.chordChartTooltips.filter((str) => str.practname === this.point.name);
                            // console.log('target', target[0]);
                            // console.log('str',str);
                            // console.log('s', s)
                            // s = target
                            // s = this.point.name + ": " + this.point.y + " Studies";
                            this.series.chart.series[0].nodeLookup[this.key].setState('hover');
                            s = target[0].practname + ": " + total + `${total == 1 ? ' Study' : ' Studies'}` + "<br>" + target[0].pract_tooltip
                          } else {
                            // if (this.point.fromNode) {
                              s = this.point.fromNode.name + " → " + this.point.toNode.name+ ": <b>" + this.point.weight + "</b><br/>"
                            // } else {
                            //     console.log('two', this)
                            //     s = this.point.name + ": " + this.point.sum + " Studies";}
                            }
                        return s;
                    },
                    style: {
                        fontSize: '12px',
                        whiteSpace: 'pre-wrap',
                        width: '200px'
                    }
                    // nodeFormat: '{point.name}: <b>{point.sum} Studies</b><br/>',
                    // pointFormat: '{point.fromNode.name} → {point.toNode.name}: <b>{point.weight} Studies</b><br/>'
                },
                series: [
                    {
                        type: "dependencywheel",
                        data: formattedData,
                        name: '',
                        // main culpret
                        dataLabels: {
                            distance: 12,
                            enabled:true, 
                            color: '#333',
                            textPath: {
                                enabled: true,
                                attributes: {dy: 5},
                            },
                            padding: 10,
                            nodeFormatter:  function(){
                                var sum = 0;
                                for (const [key, node] of Object.entries(this.series.nodeLookup)) {
                                    sum = sum+node.sum
                                };
                                if (this.point.sum / sum > 0.1) {return this.point.name} else { return undefined}; 
                            }
                        },
                        size: '80%',
                        nodePadding: nodePadding,
                        cursor: 'pointer',
                        events: {
                            click: (e) => {
                                let violinTitle = e.point.name;
                                if (e.point.shapeType === "arc") {
                                    let practCode = null;
                                    if (e.point.linksFrom.length > 0) practCode = e.point.linksFrom[0].options['practicecode_from'];
                                    if (e.point.linksTo.length > 0) practCode = e.point.linksTo[0].options['practicecode_to'];
                                    handleArcClick(violinTitle, history, practCode);
                                } else {
                                    //then its a band
                                    let toCode;
                                    let fromCode;
                                    let og = e.point
                                    // console.log('e.point', og);
                                    if(e.point.practicecode_from){
                                        fromCode = e.point.practicecode_from;
                                    }
                                    if(e.point.practicecode_to){
                                        toCode = e.point.practicecode_to;
                                    }
                                    if(toCode && fromCode){
                                        let practCode = toCode + ',' +  fromCode;
                                        violinTitle = e.point.from + ' and ' + e.point.to;
                                        // console.log('practice code', practCode);
                                        // console.log('violinTitle', violinTitle);
                                        handleArcClick(violinTitle, history, practCode, 'band');
                                    }
                                    // return null
                                }
                            }
                        }
                    },
                    {
                        type: 'pie',
                        zIndex: 0,
                        size: '80%',
                        data: formattedArcLabels_results,
                        colors: ['none'],
                        borderWidth: 0,
                        dataLabels: {
                            connectorShape: 'crookedLine',
                            connectorColor: "#333",
                            crookDistance: '120%',
                            alignTo: 'toPlotEdges',
                            slicedOffset:0,
                            // distance: '10%',
                            // this section = the pointers
                            formatter: function () { 
                                var shapeArgs = this.series.chart.series[0].nodeLookup[this.key].shapeArgs;
                                var alpha = Math.atan2((Math.sin(shapeArgs.start) + Math.sin(shapeArgs.end))/2, (Math.cos(shapeArgs.start) + Math.cos(shapeArgs.end))/2)
                                var x = shapeArgs.x+ Math.cos(alpha)*(shapeArgs.r+nodePadding)
                                var y = shapeArgs.y+ Math.sin(alpha)*(shapeArgs.r+nodePadding)
                                var coords = this.point.labelPosition.connectorPosition.touchingSliceAt;
                                coords.x = x;
                                coords.y = y;
                                return this.key;
                            },
                            filter: {
                                property: 'percentage',
                                operator: '<',
                                value: 10
                            },
                        },
                    }
                ],
                chart: {
                    spacingTop: 0,
                    marginTop: 40,
                    spacingBottom: 0,
                    marginBottom: 0,
                    zoomBySingleTouch: true,
                },
                credits: {
                    enabled: false
                },
                responsive: {
                    rules: [{
                        condition: {
                            minWidth: '2000px'
                        },
                        chartOptions: {
                            color: 'red'
                        }
                    },
                ]
                }
            }
        })
        .catch(e => {
            console.log('e', e);
            StateHookSvc.setShowBuffer(false);
        })
    }

    function getIndividualResourceConerns(res, number){
        // this function gets individual resource components + total study + observation count
        let uniqVals = [];
        let studyCount = 0;
        let obsCount = 0;
        /*
        if(StateHookSvc.includeBenchmark == true && StateHookSvc.includeControl == false){
                // when only Benchmark is true, exclude include controls
                
                responseData = responseData.filter(i => i.studytype !== 'treatcontrol');
                if(!responseData.length){
                    return null
                }
            } else if (StateHookSvc.includeControl == true && StateHookSvc.includeBenchmark == false){
                // when includeControl is true, exclude benchmark
                responseData = responseData.filter(i => i.studytype == 'treatcontrol');
                if(!responseData.length){
                    return null
                }
            }
        */
        // console.log('res argument', res)
       if(StateHookSvc.includeBenchmark == true && StateHookSvc.includeControl == false){
           res = res.filter(i => i.studytype !== 'treatcontrol');
           if(!res.length){
               return null;
           }
       }
       else if (StateHookSvc.includeControl == true && StateHookSvc.includeBenchmark == false){
           res = res.filter(i => i.studytype == 'treatcontrol');
           if(!res.length){
               return null;
           }
       };
        if(number == 1){
            for (let i = 0; i < res.length; i++) {
                if (uniqVals.indexOf(res[i].resource_component) < 0) {
                    uniqVals.push(res[i].resource_component);
                }
            };
            uniqVals.sort(function(a,b){
                return a.practname > b.practname ? 1 : -1;
            });
            StateHookSvc.setUniqViolinResources(uniqVals);
        }
        else {
            for (let i = 0; i < res.length; i++) {
                if (uniqVals.indexOf(res[i].data_elements) < 0) {
                    uniqVals.push(res[i].data_elements);
                }
            };
            uniqVals.sort(function(a,b){
                return a.practname > b.practname ? 1 : -1;
            });
            StateHookSvc.setUniqSecondViolinResources(uniqVals);
        }        
    }

    const fetchLandUseIds = async () => {
		let url = process.env.REACT_APP_APIHOST + '/get_landuse';
		return fetch(url)
			.then(res => {
				if (res.ok) {
					return res.json();
				}
			})
			.then(resJson => {
				let dataResponse = resJson[0].get_landuse;
                return dataResponse
			})
			.catch(e => console.warn(e)
		)
	};



    const wrap = (s, w) => s.replace(
		new RegExp(`(?![^\\n]{1,${w}}$)([^\\n]{1,${w}})\\s`, 'g'), '$1<br/>'
	);

    function fetchStateList(){
        if(StateHookSvc.selectedLandUse){
            return fetch(process.env.REACT_APP_APIHOST + '/get_state', {
                method: 'POST',
				headers: {
					'content-type': 'application/json'
				},
				body: JSON.stringify(
					{
						'landuseid': StateHookSvc.selectedLandUse.landuse_id,
					}
				)
            })
            .then(res => {
                if(res.ok){
                    return res.json();
                }
            })
            .then(resJson => {
                console.log('db response get_state', resJson)
                let tmpStateList = resJson[0].get_state;
                let stateOptions = [];
                tmpStateList.forEach(
                    (stateItem) => {
                        stateOptions.push({
                            "label": stateItem.state_name,
                            "value": stateItem.fips
                        });
                    }
                );
                StateHookSvc.setStateList(stateOptions);
            })
            .catch(e => console.log('error', e));
        }
        else{
            return console.warn('please select a land use first')
        }
    }

    // function fetchFilteredSite(){
    //     if (StateHookSvc.selectedLandUse) {
	// 		let filterObj = {};
    //         // extracting the landuseid from the hook
	// 		filterObj['landuseid'] = StateHookSvc.selectedLandUse.landuse_id;
	// 		// if (StateHookSvc.selectedState) {
	// 		// 	if (StateHookSvc.selectedState.value > 0 ? filterObj['fips'] = StateHookSvc.selectedState.value : null);
	// 		// }
	// 		if (StateHookSvc.selectedPracticeCode) {
	// 			if (StateHookSvc.selectedPracticeCode > 0 ? filterObj['practicecode'] = StateHookSvc.selectedPracticeCode : null);
	// 		}
    //         if (StateHookSvc.selectedResourceComponent_id) {
    //             if (StateHookSvc.selectedResourceComponent_id > 0 ? filterObj['rescomponent'] = StateHookSvc.selectedResourceComponent_id : null);
    //         }
    //         if (StateHookSvc.selectedDataElem_id) {
    //             if (StateHookSvc.selectedDataElem_id > 0 ? filterObj['dataelement_id'] = StateHookSvc.selectedDataElem_id : null);
    //         }
    //         console.log('filterObj', filterObj);
	// 		let slParams = {
	// 			method: 'POST',
	// 			headers: {
	// 				'content-type': 'application/json'
	// 			},
	// 			body: JSON.stringify(filterObj)
	// 		};
	// 		fetch(
	// 			process.env.REACT_APP_APIHOST + '/get_refids2',
	// 			slParams,
	// 		)
	// 		.then(res => {
    //             return res.json()})
	// 		.then(resJson => {
    //             // console.log('resJson get_refids2', resJson);
    //             if (resJson[0].get_refids2 && (resJson[0].get_refids2 != [])) {
    //                 let tmpRefIDs = resJson[0].get_refids2;
    //                 let tmpSelSites = tmpRefIDs.map((site) => {
    //                     return ("(ref_id = " + site.ref_id + ")")
    //                 }).join(" or ");
    //                 StateHookSvc.setSelectedSites(tmpSelSites);
    //                 console.log('tempSelSites', tmpSelSites)
    //             }
	// 		}
	// 		)
	// 		.catch(error => console.error(error))
	// 	}
    // };

    function fetchSecondViolin(){
        StateHookSvc.setShowBuffer(true);
        StateHookSvc.setUniqSecondViolinResources([]);
        let url = process.env.REACT_APP_APIHOST + '/get_percentchange_measuredelements2';
        return fetch(url, {
            method: 'POST',
            headers: {
                'content-type' : 'application/json'
            },
            body: JSON.stringify({
                'landuseid': StateHookSvc.selectedLandUse.landuse_id,
                'practicecode': StateHookSvc.selectedPracticeCode,
                'resourcecomponent': StateHookSvc.selectedResourceComponent
            })
        })
        .then(res => {
            if(res.ok){
                return res.json()
            }
        })
        .then(resJson => {
            // console.log('fetch second violin data', resJson[0].get_percentchange_measuredelements);
            let responseData = resJson[0].get_percentchange_measuredelements2;
            StateHookSvc.setUnfilteredSecondViolinRes(responseData);
            if(responseData == null){
                return null
            }
            getIndividualResourceConerns(responseData, 2)
            if(StateHookSvc.includeBenchmark == true && StateHookSvc.includeControl == false){
                // when only Benchmark is true, exclude include controls
                responseData = responseData.filter(i => i.studytype !== 'treatcontrol');
            } else if (StateHookSvc.includeControl == true && StateHookSvc.includeBenchmark == false){
                // when includeControl is true, exclude benchmark
                responseData = responseData.filter(i => i.studytype == 'treatcontrol');
            }
            responseData = responseData.filter(i => i.resource_component !== 'Any')
            responseData.sort(function(a,b){
                return a.data_elements < b.data_elements ? 1 : -1;
            });
            if(!responseData.length){
                return null
            } else {
                let popupValues_2 = [];
                for(let i = 0; i < responseData.length; i++){
                    let row = responseData[i];
                    popupValues_2.push([row["meanvalue"], row["maxvalue"], row["minvalue"]]);
                }
                let violinObject = [{
                    type: 'violin',
                    name: 'Relative Difference',
                    x: unpack(responseData, 'data_elements'),
                    x: StateHookSvc.showNormData == true? unpack(responseData, 'pct_avgannchange_normal'): unpack(responseData, 'pct_avgannchange'),
                    points: 'all',
                    spanmode: 'hard',
                    scalemode: 'width',
                    width: 0.5, 
                    span: true,
                    // hoverinfo: "skip",
                    hoveron: "points",
                    marker: {
                                    line: {
                                        width: 2,
                                        color: "#bebada"
                                    },
                                    symbol: "circle-open",
                                    color: '#96B9D0',
                                    size: 2,
                                    opacity: 1
                                },
                    line: {
                        color: "#20558A"
                    },
                    jitter: 0.8,
                    pointpos: -1,
                    size: 1,
                    // color: '#7894B0',
                    meanline: {
                        visible: true,
                        width: 6,
                        color: 'rgb(255, 255, 0)'  
                    },
                    side: 'positive',
                    fillcolor: "#20558A",
                    // opacity: 0.6,
                    box: {
                        visible: false,  
                        line:  {
                        color: "black"
                        },
                        width: 1
                    },
                    transforms: [{
                        type: 'groupby',
                        groups: unpack(responseData, 'data_elements'),
                    }],
                    customdata: popupValues_2,
                    hovertemplate: "Min: %{customdata[2]} <br> Max: %{customdata[1]} <br> Mean: %{customdata[0]}<extra></extra>",
                }];
                return violinObject
            }
        })
    }

    function formatArcLabels(theData, formattedArcLabels, dataTableLabels) {
		// get all instances of resource components from 'to' and 'from'
		let arc_catagories = [];
		let arc_catagories_code = [];
		let label_length = 16;
		for (let i = 0; i < theData.length; i++) {
			let fromLabel = theData[i].from;
			let fromPracticeCodeLabel = theData[i].practicecode_from;
			let toLabel = theData[i].to;
			let toPracticeCodeLabel = theData[i].practicecode_to;

			if (arc_catagories.indexOf(fromLabel) < 0) {
				arc_catagories.push(fromLabel);
				arc_catagories_code.push(fromPracticeCodeLabel);
			}
			if (arc_catagories.indexOf(toLabel) < 0) {
				arc_catagories.push(toLabel);
				arc_catagories_code.push(toPracticeCodeLabel);
			}
		}
        StateHookSvc.setConservationDropdown(arc_catagories);
		//get the total weight
		let arc_catagories_weight = []
		for (let i = 0; i < arc_catagories.length; i++) {
			let totalWeight = 0
			for (let j = 0; j < theData.length; j++) {
				if (arc_catagories[i] == theData[j].from || arc_catagories[i] == theData[j].to) {
					totalWeight = totalWeight + theData[j].weight
				}
			}
			arc_catagories_weight.push(totalWeight)
		}
        StateHookSvc.setTotalWeightArr(arc_catagories_weight);
		//then, loop through arc_catagories + arc_catagories_weight and attach the colors
		let theColors = chartColors //['rgb(21, 60, 106)', 'rgb(32, 84, 147)', 'rgb(62, 126, 202)', 'rgb(120, 165, 219)', 'rgb(193, 218, 246)', 'rgb(73, 117, 120)', 'rgb(107, 174, 178)', 'rgb(129, 202, 207)', 'rgb(168, 240, 245)', 'rgb(230, 252, 254)'];
		let colorIndex = 0
		for (let i = 0; i < arc_catagories.length; i++) {
			let tempData = {};
			let tempDataTable = {};
			if (colorIndex > theColors.length) {
				colorIndex = 0
			}
			tempData.name = arc_catagories[i];
			tempData.y = arc_catagories_weight[i];
			// tempDataTable goes into the MUI dataTable in DataTableTemplate.jsx
			tempDataTable.name = arc_catagories[i];
			tempDataTable.y = arc_catagories_weight[i];
			tempDataTable.code = arc_catagories_code[i];
			//maybe the color should be black to be more visable?
			tempData.dataLabels = { 'connectorColor': theColors[colorIndex] };
			formattedArcLabels.push(tempData);
			dataTableLabels.push(tempDataTable);
			colorIndex++
		}
		return formattedArcLabels
	}
    
    function handleArcClick(violinTitle, history, practCode, clickType) {
        let targetTooltip = StateHookSvc.chordChartTooltips.filter((val) => val.practname == violinTitle);
        // console.log('targetTooltip', targetTooltip);
        if(targetTooltip.length){
            StateHookSvc.setConPracTooltip(targetTooltip[0]);
        }
        StateHookSvc.setSelectedResourceComponent('');
		StateHookSvc.setViolinPlotTitle(violinTitle);
        StateHookSvc.setSelectedPracticeCode(practCode);
        StateHookSvc.handleRemoveToggleButton(true);
        StateHookSvc.setHideStateFilter(true);
        if(clickType == 'band'){
            StateHookSvc.setChordArcClick(true);
        }else{
            StateHookSvc.setChordArcClick(false);
        }
       
		history.push("/explore/violin");
	};

    function unpack(rows, key) {
        // console.log('row key', rows, key)
        return rows.map(function(row) { return row[key] });
    };

    function fetchListTableData() {
        StateHookSvc.setShowBuffer(true);
		return fetch(process.env.REACT_APP_APIHOST + '/get_practicelist', {
			method: 'POST',
			headers: {
				'content-type': 'application/json'
			},
			body: JSON.stringify(
				{
					landuseid: StateHookSvc.selectedLandUse.landuse_id
				}
			)
		})
        .then(res => {
            if (res.ok) {
                return res.json()
            }
        })
        .then(resJson => {
            if (!StateHookSvc.dataTableLabels || StateHookSvc.dataTableLabels == [] || StateHookSvc.dataTableLabels.length == 0) {
                StateHookSvc.setDataTableLabels(resJson[0].get_practicelist);
            }
            StateHookSvc.setShowBuffer(false);
        })
        .catch(e => console.log('e', e))
	}

    function getCounts(){
        return fetch(process.env.REACT_APP_APIHOST + '/get_counts2', {
            method: 'POST',
			headers: {
				'content-type': 'application/json'
			},
            body: JSON.stringify({
                'landuseid': StateHookSvc.selectedLandUse.landuse_id,
                'practicecode': StateHookSvc.selectedPracticeCode,
                'rescomponent': StateHookSvc.selectedResourceComponent_id,
                'dataelement_id': StateHookSvc.selectedDataElem_id
            })
        })
        .then(res => {
            if(res.ok){
                return res.json();
            }
        })
        .then(resJson => {
            console.log('resJson', resJson);
            StateHookSvc.setTotalStudyCount(resJson[0].get_counts2[0].studycount);
            StateHookSvc.setTotalObsCount(resJson[0].get_counts2[0].observations);
            StateHookSvc.setStudySiteCount(resJson[0].get_counts2[0].studysitecount);
        })
        .catch(e => console.log('error', e));
    };

    function filterMeasuredElementArray(val, history){
        StateHookSvc.setShowBuffer(true);
        let container = [];
        if(StateHookSvc.unfilteredSecondViolinRes == null || !StateHookSvc.unfilteredSecondViolinRes.length){
            console.log('edge case found');
        }
        console.log('StateHookSvc.unfilteredSecondViolinRes', StateHookSvc.unfilteredSecondViolinRes);
        for(let i = 0; i < StateHookSvc.unfilteredSecondViolinRes.length; i++){
            if(val === StateHookSvc.unfilteredSecondViolinRes[i].data_elements){
                // let relativeDiff = StateHookSvc.unfilteredSecondViolinRes[i].pct_avgannchange ?  StateHookSvc.unfilteredSecondViolinRes[i].pct_avgannchange : '-'
                let tempObj = {
                    // "Land Use": StateHookSvc.selectedLandUse.landuse,
                    // "Measured Element Name": StateHookSvc.unfilteredSecondViolinRes[i].data_elements,
                    "Measured Value": StateHookSvc.unfilteredSecondViolinRes[i].meas_value,
                    "Control/Pre-Treatment Value": StateHookSvc.unfilteredSecondViolinRes[i].compare_value,
                    "Absolute Difference": StateHookSvc.unfilteredSecondViolinRes[i].abs_diff && StateHookSvc.unfilteredSecondViolinRes[i].abs_diff !== null ? StateHookSvc.unfilteredSecondViolinRes[i].abs_diff : '-',
                    "Units": StateHookSvc.unfilteredSecondViolinRes[i].data_units,
                    "Relative % Difference": StateHookSvc.unfilteredSecondViolinRes[i].pct_avgannchange == null ? '-' : StateHookSvc.unfilteredSecondViolinRes[i].pct_avgannchange,
                    "Group": StateHookSvc.unfilteredSecondViolinRes[i].measgroup,
                    "Control or Pre-Treatment": StateHookSvc.unfilteredSecondViolinRes[i].studytype == 'benchmark'? 'Pre-treatment': 'Control',
                    "Units": StateHookSvc.unfilteredSecondViolinRes[i].data_units,
                    "Table/Figure": StateHookSvc.unfilteredSecondViolinRes[i].tab_fig,
                    "Citation Link": retrieveFunction(StateHookSvc.unfilteredSecondViolinRes[i].ref_id),
                    "Notes": StateHookSvc.unfilteredSecondViolinRes[i].notes
                };
                // console.log('tempObj', tempObj);
                container.push(tempObj);
            }
        }
        // console.log('container', container)
        StateHookSvc.setFinalTableCells(container);
        StateHookSvc.setRenderFinalTable(true);
        history.push('/explore/table-results');
        StateHookSvc.setShowBuffer(false);
    };

    function retrieveFunction(val){
        console.log('val', val);
        console.log('hook',StateHookSvc.references[0]);
        for(let i = 0; i < StateHookSvc.references.length; i++){
            if(StateHookSvc.references[i].ref_id == val){
                return StateHookSvc.references[i].reflink
            }
        }
    }

    function getCitations(){
        let url = process.env.REACT_APP_APIHOST + '/get_refs2';
        return fetch(url, {
            method: 'POST',
			headers: {
				'content-type': 'application/json'
			},
            body: JSON.stringify({
                'landuseid': StateHookSvc.selectedLandUse.landuse_id,
                'practicecode': StateHookSvc.selectedPracticeCode,
                'rescomponent': StateHookSvc.selectedResourceComponent_id,
                'dataelement_id': StateHookSvc.selectedDataElem_id
            })
        })
        .then(res => {
            if(res.ok){
                return res.json();
            }
        })
        .then(resJson => {
            let refRes = resJson[0].get_refs2;
            refRes.sort((a,b) => {return a.reference < b.reference ? -1 : 1});
            StateHookSvc.setReferences(refRes);
            if (resJson[0].get_refs2 && (resJson[0].get_refs2 != [])) {
                let tmpRefIDs = resJson[0].get_refs2;
                let tmpSelSites = tmpRefIDs.map((site) => {
                    return ("(ref_id = " + site.ref_id + ")")
                }).join(" or ");
                StateHookSvc.setSelectedSites(tmpSelSites);
            }
        })
        .catch(e => console.log('e', e))
    };

    function getChordChartTooltips(){
        let url = process.env.REACT_APP_APIHOST + '/gettooltips_practices';
        return fetch(url, {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({})
        })
        .then((res) => {
            return res.json()
        })
        .then((resJson) => {
            let tooltips = resJson[0].gettooltips_practices;
            StateHookSvc.setChordChartTooltips(tooltips);
        })
        .catch((err) => console.log('getChordChartTooltips err', err))
    };

    function getFirstViolinTooltips(){
        let url = process.env.REACT_APP_APIHOST + '/gettooltips_resourceconcerns';
        return fetch(url, {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({})
        })
        .then((res) => {
            return res.json()
        })
        .then((resJson) => {
            let tooltips = resJson[0].gettooltips_resourceconcerns;
            console.log('tooltips', tooltips);
            StateHookSvc.setFirstViolinTooltips(tooltips);
        })
        .catch((err) => console.log('getChordChartTooltips err', err))
    };

    function getAbstract(int){
        let url = process.env.REACT_APP_APIHOST + '/get_abstract';

        return fetch((url), {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({'studyid': int})
        });
        // .then((res) => {
        //     return res.json();
        // })
        // .then((resJson) => {
        //     return console.log('resJson', resJson);
        // })
        // .catch((err) => {console.log('getAbstract error', err)});
    }

    const value = {
        fetchViolinData: fetchViolinData,
        fetchChordChartData: fetchChordChartData,
        fetchLandUseIds: fetchLandUseIds,
        formatViolinObject: formatViolinObject,
        fetchListTableData: fetchListTableData,
        fetchSecondViolin: fetchSecondViolin,
        handleArcClick: handleArcClick,
        pushToSecondViolin: pushToSecondViolin,
        fetchStateList: fetchStateList,
        // fetchFilteredSite: fetchFilteredSite,
        getCounts: getCounts,
        getCitations: getCitations,
        getChordChartTooltips: getChordChartTooltips,
        filterMeasuredElementArray: filterMeasuredElementArray,
        getAbstract: getAbstract,
        getFirstViolinTooltips: getFirstViolinTooltips,
        setDataTableLabels: (val) => setDataTableLabels(val),
        setSelectedState: (val) => setSelectedState(val),
        setSelPracCode: (val) => setSelPracCode(val),
        handleUniqueValState: (val) => handleUniqueValState(val),
        setLandUseArray: (val) => setLandUseArray(val),
        setShowNormalizedData: (val) => setShowNormalizedData(val),
    }

    return(
        <DataContext.Provider value={value}>
            {children}
        </DataContext.Provider>
    );
};

export const useData = () => {
    return useContext(DataContext);
};